async function signup({
    firstname,
    lastname,
    email,
    username,
    password,
}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/users`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email,
                username,
                password,
            }),
        },
    );
    if (response.ok) {
        return {
            status: true,
        };
    }
    return {
        status: false,
        ...await response.json(),
    };
}

const getUsersByFilter = ({ groupId }) => {
    return async function() {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/v1/users?groupId=${groupId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        if (response.ok) {
            return await response.json();
        } else {
            return [];
        }
    }
}

export {
    signup,
    getUsersByFilter,
}