import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CodingStatusStar from './CodingStatusStar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CodingsDisplayTable({ codings }) {

  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="codings table">
        <TableHead>
        <StyledTableRow>
            <StyledTableCell>Referent</StyledTableCell>
            <StyledTableCell>Meaning Val</StyledTableCell>
            <StyledTableCell align="right">SR</StyledTableCell>
            <StyledTableCell align="right">refLvl</StyledTableCell>
            <StyledTableCell align="right">DIM</StyledTableCell>
            <StyledTableCell align="right">TR</StyledTableCell>
            <StyledTableCell align="right">FR</StyledTableCell>
            <StyledTableCell align="right">FE</StyledTableCell>
            <StyledTableCell align="right">SS</StyledTableCell>
            <StyledTableCell align="right">MM</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
        </StyledTableRow>
        </TableHead>
        <TableBody>
        {codings.map((row) => (
            <StyledTableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <StyledTableCell component="th" scope="row">
                {row.referent}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
                {row.meaningVal}
            </StyledTableCell>
            <StyledTableCell align="right">{row.sr}</StyledTableCell>
            <StyledTableCell align="right">{row.refLvl}</StyledTableCell>
            <StyledTableCell align="right">{row.dim}</StyledTableCell>
            <StyledTableCell align="right">{row.tr}</StyledTableCell>
            <StyledTableCell align="right">{row.fr}</StyledTableCell>
            <StyledTableCell align="right">{row.fe}</StyledTableCell>
            <StyledTableCell align="right">{row.ss}</StyledTableCell>
            <StyledTableCell align="right">{row.mm}</StyledTableCell>
            <StyledTableCell align="right"><CodingStatusStar small edit={null} reference={row.reference}/></StyledTableCell>
            </StyledTableRow>
        ))}
        </TableBody>
    </Table>
    </TableContainer>
  );
}