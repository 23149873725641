async function createAnswer({questionnaireId, inputId, answerText, freetext}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/answers`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                questionnaireId,
                inputId,
                answerText,
                freetext,
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return {};
};

async function updateAnswer({answerId, answerText}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/answers`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                answerId,
                answerText,
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return {};
};

export {
    createAnswer,
    updateAnswer,
}