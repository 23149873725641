import { useState } from 'react';
import { InputLabel, Button } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { signup } from '../../apis';
import { Alert } from '../../components';

export default function Login({ login }) {
  const [alertState, setAlertState] = useState({ message: '', severity: 'error', open: false });
  const [formState, setFormState] = useState({
    isLoginLayout: true,
    touched: false,
    login: {
      username: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      password: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
    },
    signup: {
      firstname: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      lastname: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      email: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : true !== /^[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*@[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*(\.[a-zA-Z]+)+$/.test(val) ? 'Invalid email' : '',
      },
      username: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : !/^[a-zA-Z0-9]+(\.[0-9a-zA-Z]+)*$/g.test(val) ? 'Username can contain only latin letters, numbers and dots in between' : ''
      },
      password: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : val !== formState.signup.repeatpassword.value ? 'Passwords do not match' : ''
      },
      repeatpassword: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : val !== formState.signup.password.value ? 'Passwords do not match' : ''
      },
    }
  });

  const switchForm = () => setFormState({
    isLoginLayout: !formState.isLoginLayout,
    touched: false,
    login: {
      username: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      password: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
    },
    signup: {
      firstname: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      lastname: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : ''
      },
      email: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : true !== /^[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*@[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*(\.[a-zA-Z]+)+$/.test(val) ? 'Invalid email' : '',
      },
      username: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : !/^[a-zA-Z0-9]+(\.[0-9a-zA-Z]+)*$/g.test(val) ? 'Username can contain only latin letters, numbers and dots in between' : ''
      },
      password: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : val !== formState.signup.repeatpassword.value ? 'Passwords do not match' : ''
      },
      repeatpassword: {
        value: '',
        errMsg: 'Required field',
        validator: val => '' === val.trim() ? 'Required field' : val !== formState.signup.password.value ? 'Passwords do not match' : ''
      },
    }
  });

  const handleLogin = () => {
    const errorsExist = Object.values(formState.login).reduce((acc, next) => acc || '' !== next.errMsg, false);
    if (errorsExist) {
      setFormState({...formState, touched: true});
    } else {
      login(formState.login.username.value, formState.login.password.value)
    }
  }

  const handleSignup = async () => {
    const errorsExist = Object.values(formState.signup).reduce((acc, next) => acc || '' !== next.errMsg, false);
    if (errorsExist) {
      setFormState({...formState, touched: true});
    } else {
      const result = await signup(Object.entries(formState.signup).reduce((acc, [key, val]) => ({...acc, [key]: val.value}), {}));
      if (result.status) {
        setAlertState({
          message: "Sign up successful. You can login now.",
          severity: "success",
          open: true
        });
        switchForm();
      } else {
        setAlertState({
          message: `Sign up failed: ${result.error === 'duplicate email' ? 'This email was already used' : 'This username is already taken'}`,
          severity: "error",
          open: true
        });
      }
    }
  };

  const runOnEnter = func => ({ keyCode }) => {if(13 === keyCode) func();}

  return (
    <div style={{ display: 'block', width: '60%', margin: '4em auto 0em auto'}}>
      {formState.isLoginLayout && (
        <>
          <FormControl fullWidth error={formState.touched && '' !== formState.login.username.errMsg}>
            <InputLabel>{`Username / Email`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Username / Email`}
              value={formState.login.username.value}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  login:{
                    ...formState.login,
                    username: {
                      ...formState.login.username,
                      errMsg: formState.login.username.validator(value),
                      value
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.login.username.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.login.password.errMsg}>
            <InputLabel>{`Password`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Password`}
              value={formState.login.password.value}
              type='password'
              onKeyDown={runOnEnter(handleLogin)}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  login: {
                    ...formState.login,
                    password: {
                      ...formState.login.password,
                      errMsg: formState.login.password.validator(value),
                      value,
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.login.password.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <Button color='primary' style={{width: '100%'}} onClick={handleLogin}>Login</Button>
          <Button
            variant='outlined'
            style={{width: '100%', marginTop: '2em'}}
            onClick={switchForm}
          >
            Not a member? SIGN UP
          </Button>
        </>
      )}
      {!formState.isLoginLayout && (
        <>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.firstname.errMsg}>
            <InputLabel>{`First name`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`First name`}
              value={formState.signup.firstname.value}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    firstname: {
                      ...formState.signup.firstname,
                      errMsg: formState.signup.firstname.validator(value),
                      value,
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.firstname.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.lastname.errMsg}>
            <InputLabel>{`Last name`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Last name`}
              value={formState.signup.lastname.value}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    lastname: {
                      ...formState.signup.lastname,
                      errMsg: formState.signup.lastname.validator(value),
                      value,
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.lastname.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.email.errMsg}>
            <InputLabel>{`Email`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Email`}
              value={formState.signup.email.value}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    email: {
                      ...formState.signup.email,
                      errMsg: formState.signup.email.validator(value.trim()),
                      value: value.trim(),
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.email.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.username.errMsg}>
            <InputLabel>{`Username`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Username`}
              value={formState.signup.username.value}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    username: {
                      ...formState.signup.username,
                      errMsg: formState.signup.username.validator(value.trim()),
                      value: value.trim(),
                    },
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.username.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.password.errMsg}>
            <InputLabel>{`Password`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Password`}
              value={formState.signup.password.value}
              type='password'
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    password: {
                      ...formState.signup.password,
                      errMsg: '' === value.trim() ? 'Required field' : value !== formState.signup.repeatpassword.value ? 'Passwords do not match' : '',
                      value,
                    },
                    repeatpassword: {
                      ...formState.signup.repeatpassword,
                      errMsg: value === formState.signup.repeatpassword.value ? '' : '' !== formState.signup.repeatpassword.value ? 'Passwords do not match' : formState.signup.repeatpassword.errMsg,
                    }
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.password.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={formState.touched && '' !== formState.signup.repeatpassword.errMsg}>
            <InputLabel>{`Repeat password`}</InputLabel>
            <OutlinedInput
              disabled={false}
              label={`Repeat password`}
              value={formState.signup.repeatpassword.value}
              type='password'
              onKeyDown={runOnEnter(handleSignup)}
              onChange={({ target: { value } }) =>
                setFormState({
                  ...formState,
                  signup: {
                    ...formState.signup,
                    repeatpassword: {
                      ...formState.signup.repeatpassword,
                      errMsg: '' === value.trim() ? 'Required field' : value !== formState.signup.password.value ? 'Passwords do not match' : '',
                      value,
                    },
                    password: {
                      ...formState.signup.password,
                      errMsg: value === formState.signup.password.value ? '' : '' !== formState.signup.password.value ? 'Passwords do not match' : formState.signup.password.errMsg,
                    }
                  },
                })
              }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${formState.touched ? formState.signup.repeatpassword.errMsg : ''} `}</FormHelperText>
          </FormControl>
          <Button style={{width: '100%'}} onClick={handleSignup}>Sign Up</Button>
          <Button
            variant='outlined'
            style={{width: '100%', marginTop: '2em'}}
            onClick={switchForm}
          >
            Log In with existing user
          </Button>
        </>
      )}
      <pre>
        {/*JSON.stringify(formState, null ,2)*/}
      </pre>
      <Alert {...alertState} onClose={() => setAlertState({ ...alertState, open: false})} />
    </div>
  );
};
