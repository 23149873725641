import { Link, Typography } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Manual() {
    return (
        <>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./kms_vars_description_hebrew_1.2.pdf"
                target="_blank"
            >
                <OpenInNewIcon />The Meaning System of Kreitler & Kreitler - Variables (Hebrew)
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./list_1.pdf"
                target="_blank"
            >
                <OpenInNewIcon />The Meaning System of Kreitler & Kreitler - List 1
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./list_2.pdf"
                target="_blank"
            >
                <OpenInNewIcon />The Meaning System of Kreitler & Kreitler - List 2
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./phase_1.pdf"
                target="_blank"
            >
                <OpenInNewIcon />Introduction to the coding of meaning in the framework of the Kreitler meaning system (Phase 1)
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./phase_2.pdf"
                target="_blank"
            >
                <OpenInNewIcon />Exercise in Coding Meaning In Tables (Phase 2)
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./phase_3.pdf"
                target="_blank"
            >
                <OpenInNewIcon />Users Guide (Phase 3)
            </Link>
            <br />
            <Link
                underline="hover"
                variant="h5"
                href="./exercises_1-10.pdf"
                target="_blank"
            >
                <OpenInNewIcon />Exercises
            </Link>
        </>
    )
}