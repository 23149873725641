import { useState } from 'react';
import { InputLabel } from '@mui/material';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import LanguageSelector from "./LanguageSelector";
import InputsList from "./InputsList";
import TemplateSelector from "./TemplateSelector";
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from "@mui/material/List";
import Add from '@mui/icons-material/Add';
import { Select, MenuItem } from "@mui/material";

export default function CustomTemplateCreator({
    templates,
    languages,
    translatedInputs,
    selectedLanguage,
    setSelectedLanguage,
    selectedTemplate,
    setSelectedTemplate,
}) {
    const [newFreetext, setNewFreetext] = useState(null);
    const addNewFreetext = (entry) => () => {
        setSelectedTemplate({
            ...selectedTemplate,
            freetext: [...selectedTemplate.freetext, { entry }],
        });
        setNewFreetext(null);
    };
    return (
        <Grid container spacing={2}>
            <Grid /*style={{backgroundColor:'yellow'}}*/ item xs={6}>
                <FormControl fullWidth style={{}}>
                    <InputLabel htmlFor="grouped-native-select">Use existing templates as reference</InputLabel>
                    <Select
                        id="grouped-native-select"
                        label="Use existing templates as reference"
                        onChange={({ target: { value } }) => {
                            const referenceTemplate = templates.find((({id}) => id === value));
                            setSelectedTemplate({
                                ...selectedTemplate,
                                inputs: [...referenceTemplate.inputs],
                                id: referenceTemplate.id,
                            })}
                        }
                        value={selectedTemplate.id}
                    >
                        {
                            templates.map(({ id, title }) => (
                                <MenuItem key={`global_template_${id}`} value={id}>{id} - {title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <List style={{ width: '100%', maxHeight: 528, overflowY: 'scroll' }} dense>
                    {!!selectedTemplate && !!selectedTemplate.inputs ? (
                        translatedInputs
                            .filter(({language}) => language === selectedLanguage)
                            .map(({ input_id, translation }) => (
                                <ListItem
                                    component="div"
                                    disablePadding
                                    onClick={() => {
                                        const index = selectedTemplate.inputs.indexOf(input_id);
                                        const inputs = [...selectedTemplate.inputs];
                                        if (-1 === index) {
                                            inputs.push(input_id);
                                        } else {
                                            inputs.splice(index, 1);
                                        }
                                        setSelectedTemplate({
                                            ...selectedTemplate,
                                            inputs,
                                        })
                                    }}
                                >
                                    <Checkbox
                                        edge="start"
                                        checked={selectedTemplate.inputs.includes(input_id)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemButton>
                                        <ListItemText primary={translation} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        ) : (
                            <pre>{JSON.stringify(selectedTemplate, null, 2)}</pre>
                        )
                    }
                </List>
            </Grid>
            <Grid /*style={{backgroundColor:'pink'}}*/ item xs={6}>
                <LanguageSelector
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={() => {}}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    // setSelectedLanguage={() => setSelectedLanguage(selectedLanguage)}
                    languages={languages}
                    header="Select language for this template"
                />

                <InputsList
                    selectedTemplate={selectedTemplate}
                    selectedLanguage={selectedLanguage}
                    templates={[...templates, selectedTemplate]}
                    translatedInputs={translatedInputs}
                    setSelectedTemplate={setSelectedTemplate}
                />
                <Button
                    color="secondary"
                    startIcon={<Add />}
                    endIcon={<Add />}
                    onClick={() => setNewFreetext('')}
                    sx={{width:'100%' }}
                >
                    {`Add a custom input`}
                </Button>

                {null !== newFreetext && (
                    <Dialog open>
                        <DialogTitle>
                            Add a custom input to template
                        </DialogTitle>
                        <DialogContent dividers>
                        <OutlinedInput
                            value={newFreetext}
                            onChange={({ target: { value }}) => setNewFreetext(value)}
                        />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setNewFreetext(null)} sx={{mr: 'auto'}}>Cancel</Button>
                            <Button onClick={addNewFreetext(newFreetext)}>Add Input</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Grid>
        </Grid>
    );
};