
import FormControl from "@mui/material/FormControl";
import { InputLabel, Select, MenuItem } from "@mui/material";
import ListSubheader from '@mui/material/ListSubheader';

export default function TemplateSelector({
  style,
  templates,
  translatedInputs,
  selectedTemplate,
  setSelectedTemplate,
  selectedLanguage,
  languages,
  header,
}) {
    const allTemplates =
        templates
        .map((template) => ({ ...template, inputs: template.inputs.map((input) => translatedInputs.find(({input_id}) => input_id === input))}))
        .map((template) => ({ ...template, inputs: template.inputs.map(({ input_id }) => input_id)}));

    const globalTemplates = allTemplates.filter(({studyId}) => null === studyId);
    const studyTemplates = allTemplates.filter(({studyId}) => null !== studyId)

    return (
        <FormControl fullWidth style={style}>
        <InputLabel htmlFor="grouped-native-select">{header}</InputLabel>
          {!!(studyTemplates.length) ? (
            <Select
              id="grouped-native-select"
              label={header}
              onChange={({ target: { value } }) => setSelectedTemplate(templates.find((({id}) => id === value)))}
              value={selectedTemplate.id}
            >
              <ListSubheader>Global templates</ListSubheader>
              {
                globalTemplates.map(({ id, title }) => (
                  <MenuItem key={`global_template_${id}`} value={id}>{id} - {title}</MenuItem>
                ))
              }
              <ListSubheader>Previously used templates</ListSubheader>
              {
                studyTemplates.map(({ id, title, language }) => (
                  <MenuItem
                    disabled={language !== selectedLanguage}
                    key={`study_template_${id}`}
                    value={id}
                  >
                    {id} - {title} - {languages.find(({id}) => id === language).title}
                  </MenuItem>
                ))
              }
            </Select>
          ) : (
            <Select
              id="grouped-native-select"
              label={header}
              onChange={({ target: { value } }) => setSelectedTemplate(templates.find((({id}) => id === value)))}
              value={selectedTemplate.id}
            >
              <ListSubheader>Global templates</ListSubheader>
              {
                globalTemplates.map(({ id, title }) => (
                  <MenuItem key={`global_template_${id}`} value={id}>{id} - {title}</MenuItem>
                ))
              }
            </Select>
          )
        }
      </FormControl>
    );
}