import { useReducer } from 'react';
import QuestionnaireInputWithAnswer from "./QuestionnaireInputWithAnswer";
import useDirection from '../../../../hooks/useDirection';

const expandedAccordionReducer = (state, index) => state === index ? -1 : index;

export default function QuestionnaireInputList({ answers, questionnaireId }) {
  const [expanded, setExpanded] = useReducer(expandedAccordionReducer, -1);
  const dir = useDirection()

  return (
    <>
      {answers.map((answer, index) => (
        <QuestionnaireInputWithAnswer
          expanded={index === expanded}
          onExpand={((i) => () => setExpanded(i))(index)}
          key={index}
          input={answer}
        />
      ))}
    </>
  );
}