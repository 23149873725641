import { Fragment, useContext } from "react";
import { InputLabel, Slider, Select, MenuItem } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DebugContext } from "../context/debug";

function renderField(
  {
    layout: {
      value,
      options,
      disabled,
    },
    data: {
      config: {
        type,
      },
      title,
      min_num,
      max_num,
    }
  },
  handleValueChange,
  errMsg
) {
  switch (type) {
    case "text":
      return (
        <FormControl fullWidth error={'' !== errMsg}>
          <InputLabel>{title}</InputLabel>
          <OutlinedInput
            disabled={disabled}
            label={title}
            value={value}
            onChange={({ target: { value: newValue } }) =>
              handleValueChange(newValue)
            }
            sx={{width:'100%'}}
          />
          <FormHelperText>{`${errMsg} `}</FormHelperText>
        </FormControl>
      );
    
      case "longtext":
        return (
          <FormControl fullWidth error={'' !== errMsg}>
          <InputLabel>{title}</InputLabel>
            <OutlinedInput
              disabled={disabled}
              label={title}
              value={value}
              onChange={({ target: { value: newValue } }) =>
                handleValueChange(newValue)
              }
              sx={{width:'100%'}}
              multiline
              maxRows={4}
            />
            <FormHelperText>{`${errMsg} `}</FormHelperText>
          </FormControl>
        );
    case "checkbox":
      return (
        <>
          <InputLabel>{title}</InputLabel>
          <Select
            disabled={disabled}
            value={value?.value}
            onChange={({ target: { value } }) =>
              handleValueChange(value)
            }
            sx={{width:'100%'}}
          >
            <MenuItem value={true}>TRUE</MenuItem>
            <MenuItem value={false}>FALSE</MenuItem>
          </Select>
        </>
      );
    case "slider":
      const intValue = parseInt(value) || 0;
      return (
        <>
          <InputLabel>{title}</InputLabel>
          <Slider
            value={intValue}
            onChange={({ target: { value: newValue } }) =>
              handleValueChange(newValue)
            }
            sx={{width:'100%'}}
          />
        </>
      );
    case "date":
      return (
        <FormControl fullWidth error={'' !== errMsg}>
          <div style={{color: '' !== errMsg ? 'rgb(211,47,47)' : 'rgba(0,0,0,0.6)', fontSize:'0.8em', marginLeft:'0.6em'}}>{title}</div>
          <OutlinedInput
            disabled={disabled}
            value={value}
            type={'date'}
            onChange={({ target: { value: newValue } }) =>
            handleValueChange(newValue)
            }
          />
          <FormHelperText>{`${errMsg} `}</FormHelperText>
        </FormControl>
      );
    case "number":
      return (
        <FormControl fullWidth error={'' !== errMsg}>
          <InputLabel id={`label_for_${title}`}>{title}</InputLabel>
          <OutlinedInput
            disabled={disabled}
            label={title}
            value={value}
            type="number"
            onChange={({ target: { value: newValue } }) =>
              handleValueChange(newValue)
            }
            sx={{width:'100%'}}
            inputProps={{ min: min_num, max: max_num }}
          />
          <FormHelperText>{`${errMsg} `}</FormHelperText>
        </FormControl>
      );
    case "dropdown":
      return (
        <FormControl fullWidth error={'' !== errMsg}>
          <InputLabel id={`label_for_${title}`}>{title}</InputLabel>
          <Select
            disabled={disabled}
            label={title}
            value={value.value}
            onChange={({ target: { value: newValue } }) =>
              handleValueChange(options.find(({value}) => value === newValue))
            }
          >
            {options.map(({ title, value }) => (
              <MenuItem key={value} value={value}>{title}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{`${errMsg} `}</FormHelperText>
        </FormControl>
      );
    default:
      return <div>ERROR RENDERING FIELD</div>
  }
}

export default function FR({
  checkboxes,
  fields,
  showErrors,
  handleValueChange,
  handleCheckboxChange,
}) {
  const inDebugMode = useContext(DebugContext);
  return (
    <Grid container spacing={0}>
      {fields.map((field, i) => (
          <Fragment key={field.id}>
            <Grid style={{ position:'relative', top:'-1em'}} item xs={checkboxes ? 1 : 0} sx={{mt: 'auto', mb: 'auto'}} >
              {checkboxes && (
                <Checkbox
                  disabled={!field.layout.isCheckable}
                  checked={field.layout.isChecked}
                  onChange={handleCheckboxChange(i)}
                />
              )}
            </Grid>
            <Grid style={{padding: '0.6em 0.4em'}} item xs={checkboxes ? 11 : 12} >
              <div>
                {renderField(field, handleValueChange(i), showErrors ? field.layout.errMsg : '')}
                {inDebugMode && (
                  <pre>
                    {JSON.stringify(field, null, 2)}
                  </pre>
                )}
              </div>
            </Grid>
          </Fragment>
      ))}
    </Grid>
  );
}
