import { useState, useReducer, useCallback, useMemo } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
  } from "@mui/material";
import CodingsTableRow from './Row';
import BetweenRows from './Between';
import { Grid } from "@mui/material";
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { useQuery } from "@tanstack/react-query";
import useDirection from '../../../../../hooks/useDirection';
import { getSuggestion } from '../../../../../apis';
import { Spinner } from '../../../../../components';

function getBlankCoding(tempOrder) {
    return {
        id: new Date().toISOString(),
        coding_order: tempOrder,
        referent: '',
        meaningVal: '',
        sr: '',
        refLvl: '',
        dim: '',
        tr: '',
        fr: '',
        fe: '',
        ss: '',
        mm: '',
        reference: null,
        comment: '',
    };
}

const codingsEqual = (a, b) => Object.entries(a).reduce((acc, [k, v]) => {
    if (['id', 'created_at', 'updated_at', 'deleted_at'].includes(k)) {
      return acc;
    } else {
      return acc && v === b[k];
    }
  }, true);

const suggestionReducer = (state, flag) => {
    if ('displaySuggestions' === flag) {
        return {displaySuggestions: !state.displaySuggestions, autoApply: false};
    } else {
        return {displaySuggestions: true, autoApply: !state.autoApply};
    }
}

export default function CodingsEditor({
    answerText,
    answerLanguage,
    input,
    codings,
    save,
    cancel,
    isSaving,
    isAdmin,
}) {
    const [suggetionsSettings, setSuggestionSettings] = useReducer(
        suggestionReducer,
        {
            displaySuggestions:
                'string' === typeof localStorage.getItem('codingEditor.displaySuggestions')
                ? 'true' === localStorage.getItem('codingEditor.displaySuggestions')
                : true,
            autoApply: false,
        },
    );
    const [newCoding, setNewCoding] = useState({referent: '', meaningVal: ''});
    const [originalCodings] = useState([...codings]);
    const [currentCodings, setCurrentCodings] = useState([...codings]);
    const [confirmExit, setConfirmExit] = useState(false);
    const dir = useDirection();
    const expandedAccordionReducer = (state, index) => state === index ? -1 : index;
    const [expanded, setExpanded] = useReducer(expandedAccordionReducer, -1);
    const { isLoading, data, refetch } = useQuery({
        queryKey: ['references', answerLanguage, newCoding.referent, newCoding.meaningVal],
        queryFn: getSuggestion(answerLanguage, newCoding.referent, newCoding.meaningVal),
        onSuccess: () => console.log('useQuery 2024-05-03T19:44:59.357Z'),
        enabled: false,
    });

    const codingsUpdated = useMemo(() => {
        if (originalCodings.length !== currentCodings.length) return true;
        if (0 < currentCodings.filter(c => Number.isNaN(Number(c.id))).length) return true;
        currentCodings.forEach((coding) => {
            const original = originalCodings.find(({ id }) => id === coding.id);
            if (!codingsEqual(coding, original)) return true;
        });

        return false;
    }, [currentCodings, originalCodings])

    const handleSuggestionFlagChange = (flag) => () => {
        if ('displaySuggestions' === flag) {
            localStorage.setItem(
                'codingEditor.displaySuggestions',
                JSON.stringify(!suggetionsSettings.displaySuggestions),
            );
        }
        setSuggestionSettings(flag);
    };

    const handleInsert = (index) => () => {
        if (index !== expanded) {
            setExpanded(index);
        }
        const tempState = [...currentCodings/*.filter(({ referent, meaningVal }) => !!referent || !!meaningVal )*/];
        tempState.splice(index, 0, getBlankCoding(index));
        setCurrentCodings(
            tempState
            .map((coding, i) => ({...coding, coding_order: i + 1}))
        );
    }
    const handleAddToEnd = ({ referent, meaningVal }) => () => {
        const temp = [...currentCodings, getBlankCoding(currentCodings.length + 1)];
        temp[temp.length-1].referent = referent;
        temp[temp.length-1].meaningVal = meaningVal;
        refetch();
        setCurrentCodings(temp);
        setNewCoding({referent: '', meaningVal: ''})
    }

    const onCodingChange = (index) => (field) => ({ target: { value }}) =>
        setCurrentCodings(
            currentCodings.map((coding, i) => {
                if (index === i) {
                    return {
                        ...coding,
                        [field]: value,
                    };
                } else {
                    return coding;
                }
            })
        );

    

    const onCodingMultipleChange = (index) => (values) => {
        setCurrentCodings(
            currentCodings.map((coding, i) => {
                if (index === i) {
                    return {
                        ...coding,
                        ...values,
                    };
                } else {
                    return coding;
                }
            })
        );
    };
    


    const moveDown = (index) => () => {
        if (index < currentCodings.length - 1) {
            const codings = [...currentCodings];
            const item = codings.splice(index, 1)[0];
            codings.splice(index + 1, 0, item);
            setCurrentCodings(codings.map((coding, i) => ({ ...coding, coding_order: i + 1})));
            setExpanded(expanded + 1);
        }
    };
    const moveUp = (index) => () => {
        if (index > 0) {
            const codings = [...currentCodings];
            const item = codings.splice(index, 1)[0];
            codings.splice(index - 1, 0, item);
            setCurrentCodings(codings.map((coding, i) => ({ ...coding, coding_order: i + 1})));
            setExpanded(expanded - 1);
        }
    };
    const deleteRow = (index) => () => {
        const codings = [...currentCodings];
        codings.splice(index, 1);
        setCurrentCodings(codings);
        setExpanded(-1);
    };
    const handleReferenceToggle = useCallback(
        () => {
            if (!isAdmin) return () => null;
            return (index) => (value) => () => onCodingChange(index)('reference')({target: {value}});
        },
        [isAdmin, onCodingChange]
    );
    const handleExit = useCallback(
        () => {
            if (codingsUpdated)
                return () => setConfirmExit(true)
            else
                return cancel;
        },
        [codingsUpdated, setConfirmExit, cancel]
    );

    return (
        <>
        <Dialog open maxWidth='xl' fullWidth>
            <DialogTitle>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={1}>
                        <Typography>Input:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Typography style={{float: 'rtl' === dir ? 'right' : 'left'}}>{input}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={1}>
                        <Typography>Answer:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Typography style={{float: 'rtl' === dir ? 'right' : 'left'}}>{answerText}</Typography>
                    </Grid>
                </Grid>
                <div>
                    {/* <span style={{float:'left', paddingRight:'1em'}}>Input:</span>
                    <span style={{float: 'rtl' === dir ? 'right' : 'left'}}>{input}</span> */}
                </div>
                <div>
                    {/* <span style={{float:'left', paddingRight:'1em'}}>Answer:</span>
                    <span style={{float: 'rtl' === dir ? 'right' : 'left'}}>{answerText}</span> */}
                </div>
            </DialogTitle>
            <DialogContent dividers>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Referent</InputLabel>
                        <OutlinedInput
                            disabled={false}
                            label={'Referent'}
                            value={newCoding.referent}
                            onChange={({ target: { value: newValue } }) => setNewCoding({...newCoding, referent: newValue })}
                            sx={{width:'100%'}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Meaning Val</InputLabel>
                        <OutlinedInput
                            disabled={false}
                            label={'Meaning Val'}
                            value={newCoding.meaningVal}
                            onChange={({ target: { value: newValue } }) => setNewCoding({...newCoding, meaningVal: newValue })}
                            sx={{width:'100%'}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        style={{width:'100%', height: '100%'}}
                        onClick={handleAddToEnd(newCoding)}
                    >
                        Add new coding to end of list
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Referent</TableCell>
                            <TableCell>Meaning Val</TableCell>
                            <TableCell>SR</TableCell>
                            <TableCell>refLevel</TableCell>
                            <TableCell>DIM</TableCell>
                            <TableCell>TR</TableCell>
                            <TableCell>FR</TableCell>
                            <TableCell>FE</TableCell>
                            <TableCell>SS</TableCell>
                            <TableCell>MM</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    currentCodings.map((coding, i) => 
                            <CodingsTableRow
                                key={`coding_${coding.id}`}
                                coding={coding}
                                onExpand={((index) => () => setExpanded(index))(i)}
                                expanded={i === expanded}
                                onChange={onCodingChange(i)}
                                multipleChange={onCodingMultipleChange(i)}
                                moveUp={moveUp(i)}
                                moveDown={moveDown(i)}
                                deleteRow={deleteRow(i)}
                                insert={handleInsert(i+1)}
                                editReference={handleReferenceToggle()(i)}
                                language={answerLanguage}
                                displaySuggestions={suggetionsSettings.displaySuggestions}
                                autoApplySuggestions={suggetionsSettings.autoApply}
                            />
                    )
                }
                    </TableBody>
                </Table>
            </TableContainer>
                {/* <TableHeader />
                <hr />
                {
                    currentCodings.map((coding, i) => 
                        <div key={`div_${coding.id}`}>
                            <BetweenRows
                                key={`between_${coding.id}`}
                                insert={handleInsert(i)}
                            />
                            <CodingsTableRow
                                key={`coding_${coding.id}`}
                                coding={coding}
                                onExpand={((index) => () => setExpanded(index))(i)}
                                expanded={i === expanded}
                                onChange={onCodingChange(i)}
                                moveUp={moveUp(i)}
                                moveDown={moveDown(i)}
                                deleteRow={deleteRow(i)}
                            />
                        </div>
                    )
                } */}
                {/* <BetweenRows key={`last_between`} insert={handleInsert(currentCodings.length)}/> */}
                {/* <pre>
                    {JSON.stringify(currentCodings, null, 2)}
                </pre> */}
            </DialogContent>
            <DialogActions>
                {/* <Button sx={{mr: 'auto'}} onClick={cancel}>Cancel</Button> */}
                <Button sx={{mr: 'auto'}} onClick={handleExit()}>{`EXIT${codingsUpdated ? ' WITHOUT SAVING' : ''}`}</Button>
                <FormGroup sx={{mr: 'auto'}} aria-label="position" row>
                    <FormControlLabel
                        value="top"
                        control={<Switch checked={suggetionsSettings.displaySuggestions} onChange={handleSuggestionFlagChange('displaySuggestions')} valuecolor="primary" />}
                        label="Display suggestions"
                        labelPlacement="top"
                    />
                    {/* <span>{JSON.stringify(suggetionsSettings)}</span> */}
                    <FormControlLabel
                        value="top"
                        control={<Switch disabled checked={suggetionsSettings.autoApply} onChange={handleSuggestionFlagChange('autoApply')} color="primary" />}
                        label="Automatically apply suggestion"
                        labelPlacement="top"
                    />
                </FormGroup>
                <Button color='secondary' disabled={!codingsUpdated} onClick={() => save(currentCodings)}>Save</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={isSaving}>
            <DialogContent>
                <Spinner />
                <Typography variant='h5'>Saving</Typography>
            </DialogContent>
        </Dialog>
        <Dialog open={confirmExit}>
            <DialogContent dividers>
                <Typography variant='h6'>
                    You have unsaved changes.<br />Exiting without saving will discard these changes.
                </Typography>
                <br /><br />
                <Typography variant='h5'>
                    Are you sure you want to exit without saving?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button sx={{mr: 'auto'}} onClick={() => setConfirmExit(false)}>Cancel</Button>
                <Button color='secondary' onClick={cancel}>Yes, exit without saving</Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

const srOptions = [
    {"group":"sr","value":"1","title":"Identical","additional":"Close","additional2":""},
    {"group":"sr","value":"2","title":"Opposite","additional":"Medium","additional2":""},
    {"group":"sr","value":"3","title":"Partial","additional":"Close","additional2":""},
    {"group":"sr","value":"4","title":"Modified by addition","additional":"Medium","additional2":""},
    {"group":"sr","value":"5","title":"Previous meaning value","additional":"Medium","additional2":""},
    {"group":"sr","value":"6","title":"Association","additional":"Medium","additional2":""},
    {"group":"sr","value":"7","title":"Unrelated","additional":"Distant","additional2":""},
    {"group":"sr","value":"8","title":"Verbal label","additional":"Distant","additional2":""},
    {"group":"sr","value":"9","title":"Grammatical variation","additional":"Close","additional2":""},
    {"group":"sr","value":"10","title":"Previous meaning values combined","additional":"Medium","additional2":""},
    {"group":"sr","value":"11","title":"Superordinate","additional":"Medium","additional2":""},
    {"group":"sr","value":"12a","title":"Synonym In original language","additional":"Close","additional2":""},
    {"group":"sr","value":"12b","title":"Synonym Translated in another language","additional":"Close","additional2":""},
    {"group":"sr","value":"12c","title":"Synonym Label in another medium","additional":"Close","additional2":""},
    {"group":"sr","value":"12d","title":"Synonym A different formulation of the same referent on the same level","additional":"Close","additional2":""},
    {"group":"sr","value":"13","title":"Replacement by implicit meaning value","additional":"Distant","additional2":""}
];