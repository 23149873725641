import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import Grid from '@mui/material/Grid';
import { FormRenderer, Spinner } from "../../../../components";

export default function EditDetailsDialog({
    title,
    data,
    explanation,
    cancel,
    save,
    handleValueChange,
    handleCheckboxChange,
}) {

    return (
        <Dialog open maxWidth='lg' fullWidth>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={6}>
                        <FormRenderer
                            checkboxes={data.checkboxes}
                            fields={data.fields}
                            showErrors={data.showErrors}
                            handleValueChange={handleValueChange}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/*explanation*/}
                        <pre>{JSON.stringify(data, null, 4)}</pre>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button style={{marginRight: 'auto'}} onClick={cancel}>Cancel</Button>
                <Button onClick={save}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}