import { useReducer, useState } from "react";
import { useQuery  } from '@tanstack/react-query';
import { InputLabel, Slider, Select, MenuItem } from "@mui/material";
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Button, Grid } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { Spinner, StatelessPaginatedTable } from "../../../components";
import useDebug from "../../../hooks/useDebug";

export default function StudiesAdminTool({
    groups,
    isLoading,
    selectedGroup,
    selectGroup,
    selectMe,
    userList,
    selectUser,
    selectedUser,
}) {
    const inDebugMode = useDebug();
    const debugData = (
        <pre>
            {JSON.stringify(groups, null, 2)}
            <br />
            {JSON.stringify(userList)}
            <br />
            {JSON.stringify(selectedGroup)}
            <br />
            {JSON.stringify(selectedUser)}
        </pre>
    )

    if (isLoading) return (
        <>
            {inDebugMode && debugData}
            <Spinner message={`Loading users data`} />
        </>
    );

    return (
        <>
            {inDebugMode && debugData}
          <Button
            onClick={selectMe}
            style={{width:'100%', marginBottom: '1em'}}
          >
            Show my studies
          </Button>
          <FormControl fullWidth error={'' !== ''}>
            <InputLabel id={`label_for_group_dropdown`}>Select group</InputLabel>
            <Select
              disabled={false}
              label={'Select group'}
              value={selectedGroup}
              onChange={({ target: { value: newValue } }) =>
                selectGroup(newValue)
              }
            >
              {groups.filter(g => 'admin' === g.role).map(({ id, name }) => (
                <MenuItem key={id} value={id}>{name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{`${''} `}</FormHelperText>
          </FormControl>          <FormControl fullWidth error={'' !== ''}>
            <InputLabel id={`label_for_group_dropdown`}>Select user</InputLabel>
            <Select
              disabled={0 === selectedGroup}
              label={'Select user'}
              value={selectedUser}
              onChange={({ target: { value: newValue } }) =>
                selectUser(newValue)
              }
            >
              {userList.map(({ user_id, first_name, last_name }) => (
                <MenuItem key={user_id} value={user_id}>{`${first_name} ${last_name}`}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{`${''} `}</FormHelperText>
          </FormControl>
          {/* <FormControl fullWidth error={'' !== ''}>
            <InputLabel>Email / Username</InputLabel>
            <OutlinedInput
              disabled={false}
              label={'Email / Username'}
              value={''}
              // onChange={({ target: { value: newValue } }) =>
              //   handleValueChange(newValue)
              // }
              sx={{width:'100%'}}
            />
            <FormHelperText>{`${''} `}</FormHelperText>
          </FormControl> */}
          {/* <Button style={{width:'100%', marginTop:'1em', marginBottom: '1em'}}>Search</Button>
          <Button style={{width:'100%', marginTop:'1em', marginBottom: '1em'}}>Reset Filters</Button> */}
        </>
    )
}