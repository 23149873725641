import { Spinner, StatelessPaginatedTable } from "../../../../components";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function StudiesBrowser(props) {
  if (props.isLoading) return (
    <Spinner message={`Loading studies`} />
  );

  if (0 === props.rows.length) {
    <Alert severity="info">
      <AlertTitle>Currently, you have no studies</AlertTitle>
      Click <strong>CREATE NEW STUDY</strong> to create one
    </Alert>
  }
  return (
    <StatelessPaginatedTable {...props} />
  )
}