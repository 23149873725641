import { OutlinedInput } from "@mui/material";
import { InputLabel } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

const INPUT_LABEL = 'Template name';

export default function NewTemplateConfirmation({
    language,
    template,
    templateTitle,
    setTemplateTitle,
    translatedInputs,
    errMsg,
}) {
    return (
        <>
            <div>Language: {language.title}</div>
            <List sx={{ width: '100%', maxHeight: 540, overflowY: 'scroll' }} subheader={<li />} dense>
                {0 !== template.freetext.length && (
                    <ListSubheader>Standard inputs:</ListSubheader>
                )}
                {
                    template
                    .inputs
                    .map((input) => translatedInputs.find(({input_id, language: inputLanguage}) => input_id === input && inputLanguage === language.id))
                    .map(({translation}) => (<ListItem><ListItemText primary={translation} /></ListItem>))
                }
                {0 !== template.freetext.length && (
                    <ListSubheader>Custom inputs:</ListSubheader>
                )}
                {
                    template
                    .freetext
                    // TODO: drop index
                    .map(({entry}, index) => (
                        <ListItem>
                            <ListItemText key={`freetext_${entry}_${index}`} style={{backgroundColor:'rgb(221, 255, 221)'}} primary={entry} />
                        </ListItem>
                    ))
                }
                
            </List>
            {/* <pre>
                {JSON.stringify(template, null, 2)}
            </pre> */}
            <br />
            <FormControl fullWidth error={'' !== errMsg}>
                <InputLabel>{INPUT_LABEL}</InputLabel>
                <OutlinedInput
                    label={INPUT_LABEL}
                    value={templateTitle}
                    onChange={({ target: { value } }) => setTemplateTitle(value)}
                    sx={{width:'100%'}}
                />
                <FormHelperText>{`${errMsg} `}</FormHelperText>
            </FormControl>
        </>
    );
};