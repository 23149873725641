import FormControl from "@mui/material/FormControl";
import { InputLabel, Select, MenuItem } from "@mui/material";
import InputsList from "./InputsList";

export default function TemplateSelector2({
    style,
    header,
    selectedTemplate,
    setSelectedTemplate,
    selectedLanguage,
    translatedInputs,
    templates
}) {
    
    return (
        <>
            <FormControl fullWidth style={style}>
                <InputLabel htmlFor="grouped-native-select">{header}</InputLabel>
                <Select
                    id="grouped-native-select"
                    label={header}
                    onChange={({ target: { value } }) => setSelectedTemplate(templates.find((({id}) => id === value)))}
                    value={selectedTemplate.id}
                >
                    {
                        templates.map(({ id, title }) => (
                            <MenuItem key={`global_template_${id}`} value={id}>{id} - {title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <InputsList
                selectedTemplate={selectedTemplate}
                selectedLanguage={selectedTemplate.language}
                templates={templates}
                translatedInputs={translatedInputs}
            />
        </>
    )
}