import { useFormAction, useParams } from "react-router-dom";
import { useCallback, useContext, useMemo, useState } from "react";
import QuestionnaireInputsList from "./QuestionnaireInputsList";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getQuestionnaireById, createAnswer, updateAnswer, upsertCodings, updateQuestionnaire } from '../../../../apis';
import { TextDirectionContext } from "../../../../context/textDirection";
import CodingsEditor from "./CodingsEditor";
import { FormRenderer } from "../../../../components";
import { useLocation } from "react-router-dom";
import { DebugContext } from "../../../../context/debug";
import useCredentials from "../../../../hooks/useCredentials";

// const updateAnswer = (ansId, txt) => console.log(`updating answer ${ansId} ${txt}`);
// const createAnswer = (qstnrId, inputId, txt) => console.log(`creating answer ${qstnrId} ${inputId} ${txt}`);

const SaveHeaderChanges = ({disabled, onClick, cancel}) => {
  return (
    <div>
      {disabled && (
        <Button
          style={{width: '100%', margin: '1ex 0ex 1ex 0ex'}}
          variant={"outlined"}
          color={"primary"}
          onClick={onClick}
          disabled
        >
          {'No changes - Nothing to save'}
        </Button>
      )}
      {!disabled && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Button
              style={{width: '100%', margin: '1ex 0ex 1ex 0ex', float: 'left'}}
              variant={"contained"}
              color={"primary"}
              onClick={onClick}
            >
              {'Questionnare details updated - CLICK TO SAVE'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{width: '100%', margin: '1ex 0ex 1ex 0ex', float: 'right'}}
              variant={"contained"}
              color={"error"}
              onClick={cancel}
            >
              {'Cancel Changes'}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const setLayout = (field) => ({layout: {}, data: {...field}});
const setDefaults = (field) => {
  switch (field.data.config.type) {
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      return {
        ...field,
        layout: {
          ...field.layout,
          value: field.data.default_value || field.data.value ? field.data.value.value : "",
        },
      };
    case 'checkbox':
      return {
        ...field,
        layout: {
          ...field.layout,
          value: null == field.data.value
            ? {value: 0}
            : {value: "TRUE" === field.data.value.value}
        }
      }
    case 'dropdown':
      return {
        ...field,
        layout: {
          ...field.layout,
          value:
            field.data.is_default
            ? field.data.default_value
            : field.data.value
            ? field.data.value
            : {value:0},
          options: field.data.config.options,
          // options:
          //   field.data.is_required
          //   ? [...field.data.config.options]
          //   : field.data.is_defaultable
          //   ? [{title:'NO DEFAULT VALUE SELECTED', value: 0}, ...field.data.config.options]
          //   : [{title:'DEFAULT VALUE CANNOT BE SELECTED', value: 0}, ...field.data.config.options]
        },
      };
    default:
      return field;
  }
}
const setDisabled = (field) => ({
  ...field, 
  layout: {
    ...field.layout, 
    disabled: !!field.data.default_value,
  },
});
const setValidity = (field) => {
  let validator;
  switch (field.data.config.type) {
    case 'checkbox':
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: '',
          validator: () => true,
        },
      };
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      validator = (newValue) => !(field.data.is_required && String(newValue).trim() === '') ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value),
          validator,
        },
      };
    case 'dropdown':
      validator = (newValue) => !(field.data.is_required && newValue === 0) ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value.value),
          validator,
        },
      };
    default:
      return field;
  }
}

const codingsEqual = (a, b) => Object.entries(a).reduce((acc, [k, v]) => {
  if (['id', 'created_at', 'updated_at', 'deleted_at'].includes(k)) {
    return acc;
  } else {
    return acc && v === b[k];
  }
}, true);

const wasFieldChenged = (field) => {
  let result;
  switch(field.data.config.type) {
    case 'dropdown':
      result = false
      || ((null === field.data.value) && (0 !== field.layout.value.value))
      || ((null !== field.data.value) && (field.data.value.value !== field.layout.value.value));
      break;
    case 'checkbox':
      result = false
      || ((null === field.data.value) && (0 !== field.layout.value.value))
      || ((null !== field.data.value) && (("TRUE" === field.data.value.value) && (true !== field.layout.value.value)))
      || ((null !== field.data.value) && (("FALSE" === field.data.value.value) && (false !== field.layout.value.value)));
      break;
    default:
      result = false
      || ((null === field.data.value) && ("" !== field.layout.value))
      || ((null !== field.data.value) && (field.data.value.value !== field.layout.value));
  }
  if (result) console.log(field);
  return result;
};

const getFieldValue = (field) => {  
  switch(field.data.config.type) {
    case 'checkbox':
    case 'dropdown':
      return field.layout.value.value;
    default:
      return field.layout.value;
  }
}

export default function QD() {
  const inDebugMode = useContext(DebugContext);
  const creds = useCredentials();
  // const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [headerState, setHeaderState] = useState(null);
  const updatedHeaders = useMemo(
    () => {
      if (null === headerState)
        return false;
      else {
        return false
        || headerState.questionnaireDetails.map(wasFieldChenged).reduce((acc, next) => acc || next)
      }
    },
    [headerState],
  );

  const queryClient = useQueryClient();
  const { questionnaireId } = useParams();

  const { isLoading, data, refetch: refetchQuestionnaire } = useQuery({
    queryKey: ['questionnaireById', questionnaireId],
    queryFn: getQuestionnaireById(questionnaireId),
    onSuccess: () => console.log('useQuery 2023-10-01T17:35:32.603Z'),
  });
  const mutationCreateAnswer = useMutation({
    mutationFn: createAnswer,
    onSuccess: (r, v) => {
      if (v.freetext) {
        queryClient.setQueryData(
          ['questionnaireById', questionnaireId],
          {
            ...queryClient.getQueryData(['questionnaireById', questionnaireId]),
            answers: [
              ...queryClient.getQueryData(['questionnaireById', questionnaireId]).answers.map(
                (answer) => {
                  if (answer.entry_id !== v.inputId) {
                    return answer;
                  } else {
                    return {
                      ...answer,
                      answer_id: r.answerId,
                      answer_text: v.answerText,
                    };
                  }
                }
              )
            ]
          },
        );
      } else {
        queryClient.setQueryData(
          ['questionnaireById', questionnaireId],
          {
            ...queryClient.getQueryData(['questionnaireById', questionnaireId]),
            answers: [
              ...queryClient.getQueryData(['questionnaireById', questionnaireId]).answers.map(
                (answer) => {
                  if (answer.input_id !== v.inputId) {
                    return answer;
                  } else {
                    return {
                      ...answer,
                      answer_id: r.answerId,
                      answer_text: v.answerText,
                    };
                  }
                }
              )
            ]
          },
        );
      }
      console.log(r);
      console.log(v);
    }
  })
  const mutationUpdateAnswer = useMutation({
    mutationFn: updateAnswer,
    onSuccess: (_, {answerId, answerText}) => {
      queryClient.setQueryData(
        ['questionnaireById', questionnaireId],
        {
          ...queryClient.getQueryData(['questionnaireById', questionnaireId]),
          answers: [
            ...queryClient.getQueryData(['questionnaireById', questionnaireId]).answers.map(
              (answer) => {
                if (answer.answer_id !== answerId) {
                  return answer;
                } else {
                  return {
                    ...answer,
                    answer_text: answerText,
                  };
                }
              }
            )
          ]
        },
      );
    },
  })
  const mutationUpsertCodings = useMutation({
    mutationFn: upsertCodings,
    onSuccess: (_, {answerId, answerText}) => {
      refetchQuestionnaire();
      // setAnswerProcessed(null);
      setIsSaving(false);
    },
  });
  const mutationUpdateQeustionnaire = useMutation({
    mutationFn: updateQuestionnaire,
    onSuccess: (r, v) => {
      queryClient.setQueryData(
        ['questionnaireById', questionnaireId],
        {
          ...queryClient.getQueryData(['questionnaireById', questionnaireId]),
          headers: {
            individualDetails: queryClient.getQueryData(['questionnaireById', questionnaireId]).header.individualDetails,/*.map(
              detail => {
                const match = v.individualDetails.find( ({ id }) => id === detail.id);
                if (!match)
                  return detail;
                else {
                  return {
                    ...detail,
                    value:
                      ['text','longtext','number','date'].includes(detail.config.type)
                      ? {value: match.value}
                      : 'checkbox' === detail.config.type
                      ? {value: true === match.value ? 'TRUE' : 'FALSE'}
                      : 'dropdown' === detail.config.type
                      ? detail.config.options.find(opt => match.value === opt.value)
                      : {value: 'ERROR'}
                  }
                }
              }
            ),*/
            questionnaireDetails: queryClient.getQueryData(['questionnaireById', questionnaireId]).header.questionnaireDetails.map(
              detail => {
                const match = v.questionnaireDetails.find( ({ id }) => id === detail.id);
                if (!match)
                  return detail;
                else {
                  return {
                    ...detail,
                    value:
                      ['text','longtext','number','date'].includes(detail.config.type)
                      ? {value: match.value}
                      : 'checkbox' === detail.config.type
                      ? {value: true === match.value ? 'TRUE' : 'FALSE'}
                      : 'dropdown' === detail.config.type
                      ? detail.config.options.find(opt => match.value === opt.value)
                      : {value: 'ERROR'}
                  }
                }
              }
            )
          }
        }
      );
      setHeaderState({
        // individualDetails: headerState.individualDetails.map(setDefaults),
        individualDetails: headerState.individualDetails,
        questionnaireDetails: headerState.questionnaireDetails.map(
          detail => {
            const match = r.questionnaireDetails.find(x => x.id === detail.data.id);
            if (!match) {
              return detail;
            } else {
              let newValue = match.value;
              if (["date", "text"].includes(detail.data.config.type)) {
                newValue = newValue.value;
              }
              if ("checkbox" === detail.data.config.type) {
                newValue = {value: "TRUE" === match.value.value};
              }
              return {
                ...detail,
                layout: {
                  ...detail.layout,
                  value: newValue,
                },
                data: {
                  ...detail.data,
                  value: match.value,
                }
              };
            }
          }
        ),
      })
    }
  })

  const handleCreateAnswer = (inputId, answerText, freetext) => mutationCreateAnswer.mutate({questionnaireId, inputId, answerText, freetext});
  const handleUpdateAnswer = (answerId, answerText) => mutationUpdateAnswer.mutate({answerId, answerText});
  const handleHeaderUpdate = (group) => (index) => (newValue) => {
    setHeaderState({
      ...headerState,
      [group]: headerState[group].map(
        (prop, i) => {
          if (index !== i) {
            return prop;
          } else {
            return {
              ...prop,
              layout: {
                ...prop.layout,
                value: 'checkbox' === prop.data.config.type
                  ? {value: newValue}
                  : newValue
              }
            };
          }
        }
      )
    })
  }
  const handleCodingsUpdate = (codings) => {
    const newCodings = [];
    const updatedCodings = [];
    const deletedCodings = data.answers.find(({ answer_id }) => answer_id === answerProcessed).codings.filter(({ id }) => !codings.find((newCoding) => newCoding.id === id)).map(({ id }) => id);

    codings.forEach((coding) => {
      if (Number.isNaN(Number(coding.id))) {
        newCodings.push(coding);
      } else {
        const original = data.answers.find(({ answer_id }) => answer_id === answerProcessed).codings.find(({ id }) => id === coding.id);
        if (!codingsEqual(coding, original)) {
          updatedCodings.push(coding);
        }
      }
    });
    
    setIsSaving(true);
    mutationUpsertCodings.mutate({
      answerId: answerProcessed,
      language: data.language,
      newCodings,
      updatedCodings,
      deletedCodings,
    });
  }
  const handleSave = (questionnaireId) => () => {
    mutationUpdateQeustionnaire.mutate({
      questionnaireId,
      individualDetails: [],//headerState.individualDetails.map(field => ({include: wasFieldChenged(field), value: field.layout.value})).filter((f) => !!f.include),
      questionnaireDetails: headerState.questionnaireDetails.map(field => (wasFieldChenged(field) ? {id: field.data.id, value: getFieldValue(field)} : null)).filter((f) => !!f),
    })
  };

  const cancelHeaderChanges = () => {
    setHeaderState({
      individualDetails: headerState.individualDetails.map(setDefaults),
      questionnaireDetails: headerState.questionnaireDetails.map(setDefaults),
    });
};

  const [answerProcessed, setAnswerProcessed] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  if (isLoading) return null;
  if (null === headerState) {
    setHeaderState({
      individualDetails: data.header.individualDetails.map(setLayout).map(setDefaults).map(setDisabled).map(setValidity),
      questionnaireDetails: data.header.questionnaireDetails.map(setLayout).map(setDefaults).map(setDisabled).map(setValidity),
    });
    return null;
  };

  return (
    <TextDirectionContext.Provider value={[2,3].includes(data.language) ? 'rtl' : 'ltr'}>
      {/* <pre>
        {JSON.stringify(data.header.individualDetails, null, 4)}
      </pre> */}
      {/* <SaveHeaderChanges
        disabled={!updatedHeaders}
      /> */}
          <Accordion
            expanded={expanded}
            style={{margin: '1em 0px'}}
          >
            <AccordionSummary
              onClick={() => setExpanded(!expanded || updatedHeaders)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{backgroundColor: updatedHeaders ? 'pink' : ''}}
            >
              <Typography>
                { expanded ? `Click to hide` : `Click to display individual and questionnaire details` }
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{backgroundColor: updatedHeaders ? 'pink' : ''}}>
            <SaveHeaderChanges
              disabled={!updatedHeaders}
              onClick={handleSave(questionnaireId)}
              cancel={cancelHeaderChanges}
            />
            <FormRenderer
              checkboxes={false}
              fields={headerState.questionnaireDetails}
              showErrors={false}
              handleValueChange={handleHeaderUpdate('questionnaireDetails')}
              handleCheckboxChange={()=>{}}
            />
            <SaveHeaderChanges
              disabled={!updatedHeaders}
              onClick={handleSave(questionnaireId)}
              cancel={cancelHeaderChanges}
            />
            </AccordionDetails>
          </Accordion>

      {inDebugMode && (
          <>
            <hr />
            <pre>
              {JSON.stringify(data.header.questionnaireDetails, null, 4)}
            </pre>
          </>
        )
      }
      <QuestionnaireInputsList
        answers={data.answers.map(
          (answer) => ({
            ...answer,
            upsert:
              !!answer.answer_id
              ? ((answerId) => (newText) => handleUpdateAnswer(answerId, newText))(answer.answer_id)
              : ((inputId, freetext) => (newText) => handleCreateAnswer(inputId, newText, freetext))(answer.input_id, answer.freetext),
            editCodings: () => setAnswerProcessed(answer.answer_id),
          })
        )}
        questionnaireId={questionnaireId}
      />
      {
        !!answerProcessed && (
          <CodingsEditor
            key={JSON.stringify(data)}
            input={data.answers.find(({ answer_id }) => answerProcessed === answer_id).translation}
            answerText={data.answers.find(({ answer_id }) => answerProcessed === answer_id).answer_text}
            answerLanguage={data.language}
            cancel={() => setAnswerProcessed(null)}
            save={handleCodingsUpdate}
            codings={data.answers.find(({ answer_id }) => answer_id === answerProcessed).codings}
            isAdmin={isAdmin(data.studyGroupId, creds.groups)}
            isSaving={isSaving}
          />
        )
      }
    </TextDirectionContext.Provider>
  );
}

const isAdmin = (gId, groups) => {
  const match =  groups.find(g => g.id === gId);
  return !!match && match.role === 'admin';
}