async function createTemplate({ studyId, title, language, inputs, freetext }) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/templates`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                studyId,
                title,
                language,
                inputs,
                freetext: freetext.map(({ entry }) => entry),
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return null;
}

export {
    createTemplate,
}