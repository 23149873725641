import LanguageSelector from "./LanguageSelector";
import TemplateSelector from "./TemplateSelector";
import InputsList from "./InputsList";
import { Button } from "@mui/material";
import Add from '@mui/icons-material/Add';

export default function TemplateCreator({
    selectedLanguage,
    setSelectedLanguage,
    languages,
    templates,
    newTemplate,
    setNewTemplate,
    translatedInputs,
    // openCustomTemplateDialog,
}) {
    return (
        <>
            <LanguageSelector
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                languages={languages}
                header={'Select language for the new questionnaire'}
            />
            <TemplateSelector
                style={{marginTop:'1em'}}
                templates={templates}
                translatedInputs={translatedInputs}
                selectedTemplate={newTemplate}
                setSelectedTemplate={setNewTemplate}
                selectedLanguage={selectedLanguage}
                languages={languages}
                header={'Select template for the new questionnaire'}
            />
            <InputsList
                selectedTemplate={newTemplate}
                selectedLanguage={selectedLanguage}
                templates={templates}
                translatedInputs={translatedInputs}
            />
            {/* <Button
                color="secondary"
                startIcon={<Add />}
                endIcon={<Add />}
                onClick={openCustomTemplateDialog}
                sx={{width:'100%', marginTop: '1em'}}
            >
                {`Create a new template`}
            </Button> */}
        </>
    );
};