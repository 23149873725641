import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Homepage() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  if ("/login" === location.pathname) {
    console.log("navigating");
    navigate("..");
  }
  const messages = [
    {
      date: 'February 10th 2024',
      title: 'UX improvements & Study export',
      content:
        <>
          < br/>
            <Typography variant="body2">
              {'UI displays Hebrew and Arabic in proper right-to-left'}
            </Typography>
          < br/>
            <Typography variant="body2">
              {'A study can be exported as a CSV file from the study-page listing questionnaires'}
            </Typography>
        </>,
    },
    {
      date: 'January 30th 2024',
      title: 'Bugfixes, UX improvements',
      content: 
        <>
          < br/>
            <Typography variant="body2">
              {'Multiple bugs and UX issues related to singup/login were fixed'}
            </Typography>
        </>,
    },
    {
      date: 'January 7th 2024',
      title: 'App launch',
      content: 
        <>
          < br/>
            <Typography variant="body2">
              {'Version #4 of Kreitler-Meaning-System is now accessible to new users.'}
            </Typography>
          < br/>
            <Typography variant="body2">
              {'The previous version, version #3, is still available at '}
              <a href="http://kreitlermeaningsystem.tau.ac.il">http://kreitlermeaningsystem.tau.ac.il</a>
            </Typography>
          < br/>
            <Typography variant="body2">
              {'Data from version #3 will be migrated to the new one and both links will lead to version #4.'}
            </Typography>
        </>,
    },
  ];
  return (
    <>
      <Card variant="outlined">
        <CardContent key={`tech-support`}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom></Typography>
          <Typography variant="h5" component="div">Tech support</Typography>
          <Typography variant="h6" component="div"><a href="mailto:kms4.support@proton.me">kms4.support@proton.me</a></Typography>
          <Typography variant="body1">
            Please use the email you provided on Sign-up when reporting issues.
          </Typography>
        </CardContent>
      </Card>
      <hr />
      {
        messages.map(({date, title, content}) => (
          <Card variant="outlined">
            <CardContent key={`${date}_${title}`}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {date}
              </Typography>
              <Typography variant="h5" component="div">
                {title}
              </Typography>
                {content}
            </CardContent>
          </Card>
        ))
      }
    </>
  );
}
