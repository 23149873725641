import { useState, useCallback } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export default function StarIndicator({allowEdit, edit, reference, small}) {
    const [original] = useState(reference);
    const [value, setValue] = useState(reference);
    const [hover, setHover] = useState(false);
    const handleClick = useCallback(
        () => {
            let tempValue;
            if (null === value) {
                tempValue = null === original ? 0 : original;
            } else {
                tempValue = null;
            }
            setValue(tempValue);
            edit(tempValue)();
        },
        [value, original, edit],
    )
    const props = {
        onMouseOver: allowEdit ? () => setHover(true) : ()=>{},
        onMouseOut: allowEdit ? () => setHover(false) : ()=>{},
        onClick: allowEdit ? handleClick : ()=>{},
        sx: {
            cursor: hover ? 'pointer' : 'auto',
            color: (!!reference || null !== value) ? 'gold' : hover ? 'gold': 'black',
        },
        fontSize: !!small ? 'small' : 'large',
    };
    //const icon = !!reference ? <><StarIcon {...props} /><span>{JSON.stringify(value)}</span></> : <><StarBorderIcon {...props} /><span>{JSON.stringify(value)}</span></>;
    const icon = !!reference ? <StarIcon {...props} /> : <StarBorderIcon {...props} />;
    
    return icon;
}