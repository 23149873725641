import { useState } from "react";

import { UsersBrowser } from "./components";
import useCredentials from "../../hooks/useCredentials";

export default function UsersPage() {
  const creds = useCredentials();
  return (
    <div>
      <pre>
        {JSON.stringify(creds, null, 2)}
      </pre>
    </div>
  );
}
