async function upsertCodings({answerId, language, newCodings, updatedCodings, deletedCodings}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/codings`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                answerId,
                language,
                newCodings,
                updatedCodings,
                deletedCodings,
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return {};
};

export {
    upsertCodings,
}