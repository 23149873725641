function getQuestionnairesByStudyId(studyId) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/questionnaires/study/${studyId}`);
        if (response.ok) {
            return await response.json();
        }
        return [];
    }
}

function getQuestionnaireById(questionnaireId) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/questionnaires/${questionnaireId}`);
        if (response.ok) {
            return await response.json();
        }
        return {};
    }
}

async function createQuestionnaire({userId, studyId, language, templateId}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/questionnaires`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                studyId,
                language,
                templateId,
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return {};
};

async function updateQuestionnaire({questionnaireId, individualDetails, questionnaireDetails}) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/questionnaires/${questionnaireId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                questionnaireId, 
                individualDetails,
                questionnaireDetails,
            }),
        },
    );
    return await response.json();
}

export {
    getQuestionnairesByStudyId,
    getQuestionnaireById,
    createQuestionnaire,
    updateQuestionnaire,
}