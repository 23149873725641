import React, { useState, Fragment } from "react";
import { FormRenderer, NewFieldForm, Spinner } from "../../../../components";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQuery } from '@tanstack/react-query';

import Add from '@mui/icons-material/Add';
import Alert from '../Alert';
import Summary from './summary';
import { getResourceConfig, createStudy } from "../../../../apis";
import useCredentials from "../../../../hooks/useCredentials";
import { CheckBox } from "@mui/icons-material";

const GROUP_ID = 2;

const setLayout = (field) => ({layout: {}, data: {...field}});

const setDefaults = (field) => {
  switch (field.data.config.type) {
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      return {
        ...field,
        layout: {
          ...field.layout,
          value: field.data.default_value || '',
        },
      };
    case 'dropdown':
      return {
        ...field,
        layout: {
          ...field.layout,
          value:
            field.data.default_value
            || {
              title:
                field.data.is_required 
                ? ''
                : field.data.is_defaultable
                ? 'NO DEFAULT VALUE SELECTED'
                : 'DEFAULT VALUE CANNOT BE SELECTED'
              ,
              value: 0,
            },
          options:
            field.data.is_required
            ? [...field.data.config.options]
            : field.data.is_defaultable
            ? [{title:'NO DEFAULT VALUE SELECTED', value: 0}, ...field.data.config.options]
            : [{title:'DEFAULT VALUE CANNOT BE SELECTED', value: 0}, ...field.data.config.options]
        },
      };
    default:
      return field;
  }
}

const setDisabled = (field) => ({
  ...field, 
  layout: {
    ...field.layout, 
    disabled: !!field.data.default_value || !field.data.is_defaultable,
  },
});

const setValidity = (field) => {
  let validator;
  switch (field.data.config.type) {
    case 'checkbox':
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: '',
          validator: () => true,
        },
      };
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      validator = (newValue) => !(field.data.is_required && String(newValue).trim() === '') ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value),
          validator,
        },
      };
    case 'dropdown':
      validator = (newValue) => !(field.data.is_required && newValue === 0) ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value.value),
          validator,
        },
      };
    default:
      return field;
  }
}

const studyDetailsInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>All details in this form describe the study and its questionneirs.</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>Fill in the fields marked by a gray checkbox</li>
    </ul>
  </p>
</>)

const individualDetailsInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>The list on the left is a list of all available "Individual Details"</li>
      <li>Individual details are filled-in every time a questionnaire is created</li>
      <li>A checked field will appear in the "Individual Details" for every questionnaire in this study. An unchecked one will not</li>
      <li>You can set a default value to any field, this value will be filled-in by default when a new questionnaire is created</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>(Optional) Uncheck fields not relevant for questionnaires in this stydy</li>
      <li>(Optional) Set default values on relevant fields</li>
    </ul>
  </p>
</>)

const questionnaireDetailsInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>The list on the left is a list of all available "Questionnaire Details"</li>
      <li>Questionnaire details are filled-in every time a questionnaire is created</li>
      <li>A checked field will appear in the "Questionnaire Details" for every questionnaire in this study. An unchecked one will not</li>
      <li>You can set a default value to any field, this value will be filled-in by default when a new questionnaire is created</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>(Optional) Uncheck fields not relevant for questionnaires in this stydy</li>
      <li>(Optional) Set default values on relevant fields</li>
    </ul>
    <h4>Important:</h4>
    <ul>
      <li>"External ID" is meant to hold a value that identifies the interviewee</li>
      <li>This value MUST NOT be any bit of information that identifies the interviewee. Things like: full-name, email, phone-number should not be used</li>
    </ul>
  </p>
</>)

const setCheckboxes = (field) => {
  return {
    ...field,
    layout: {
      ...field.layout,
      isChecked: field.data.is_default || field.data.is_required,
      isCheckable: !field.data.is_required,
    },
  }
}

export default function NewStudyWizard({ isOpen, cancelNewStudy }) {
  const [state, setState] = useState(null);
  const {id: userId} = useCredentials();

  useQuery({
    queryKey: ['config', 'study', GROUP_ID],
    queryFn: getResourceConfig('study', GROUP_ID),
    initialData: { studyDetails:[], personalDetails:[], questionnaireDetails: [] },
    onSuccess: ({studyDetails, individualDetails, questionnaireDetails}) => {
      if (state === null) {
        setState(s => [
          {
            title: 'Study details',
            field: 'studyDetails',
            data:
              [
                {
                  title: 'Study name',
                  config: {
                    type: 'text',
                  },
                  is_required: true,
                },
                {
                  title: 'Study Goals',
                  config: {
                    type: 'longtext',
                  },
                  is_required: true,
                },
                ...studyDetails
              ].map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes),
            showErrors: false,
          },
          { 
            title: 'Individual details',
            field: 'individualDetails',
            data: individualDetails.map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes).map(setDisabled),
            showErrors: false,
          },
          { 
            title: 'Questionnaire details',
            field: 'questionnaireDetails',
            data: questionnaireDetails.map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes).map(setDisabled),
            showErrors: false,
          },
          { title: 'Summary' },
        ]);
      }
    },
  });

  // const userId = window.sessionStorage.getItem("userId");
  const navigate = useNavigate();
  const [showNewFieldForm, setShowNewFieldForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [alertState, setAlertState] = useState({ message: '', severity: 'error', open: false })
  const mutationCreateStudy = useMutation({
    mutationFn: createStudy,
    onSuccess: ({ studyId }) => {navigate(`/studies/${studyId}`)}
  })

  const handleFinish = () => {
    // console.clear();
    // console.log(state.slice(0,3));
    const payload = state.slice(0,3).reduce(
      (acc, next) => ({
        ...acc,
        [`${next.field}`]: [
          ...next.data
            .filter((field) => field.layout.isChecked)
            .map(
              (field) => ({
                ...field.data,
                value:
                  field.data.config.type !== 'dropdown'
                  ? { value: field.layout.value }
                  : field.layout.value
              })
            )
        ],
      }),
      {},
    );
    // console.log(payload);
    mutationCreateStudy.mutate({
      userId: userId,
      groupId: GROUP_ID,
      study: payload,
    });
  };

  const handleNext = (currentStep) => () => {
    const isValid = state[currentStep].data.reduce((acc, next) => acc && ('' === next.layout.errMsg) ,true);
    setState(state.map(
      (page, i) => {
        if (i === currentStep) {
          return {
            ...page,
            showErrors: true,
          }
        } else {
          return page;
        }
      }
    ))
    if (isValid)
      setActiveStep((prevActiveStep) => prevActiveStep + 3)
    else
      setAlertState({
        message: "Please address the errors to continue",
        severity: "error",
        open: true
      });
  };

  const handleCheckboxChange = (pageIndex) => (fieldIndex) => () => setState(
    state.map((page, i) => {
      if (pageIndex === i) {
        return {
          ...page,
          data: page.data.map((field, j) => {
            if (fieldIndex === j) {
              return {
                ...field,
                layout: {
                  ...field.layout,
                  isChecked: !field.layout.isChecked,
                },
              };
            } else {
              return field;
            }
          })
        }
      } else {
        return page;
      }
    })
  );
  const handleValueChange = (pageIndex) => (fieldIndex) => (value) => setState(
    state.map((page, i) => {
      if (pageIndex === i) {
        return {
          ...page,
          data: page.data.map((field, j) => {
            if (fieldIndex === j) {
              return {
                ...field,
                layout: {
                  ...field.layout,
                  errMsg: field.layout.validator(value),
                  value,
                },
              };
            } else {
              return field;
            }
          }),
        }
      } else {
        return page;
      }
    })
  )

  function cancelNewField() {
    setShowNewFieldForm(false);
  }

  function createNewField(field) {
    // switch (activeStep) {
    //   case 0: {
    //     setStudyDetails([...studyDetails, { checked: true, data: field }]);
    //     break;
    //   }
    //   case 1: {
    //     setIndividualDetails([
    //       ...individualDetails,
    //       { checked: true, data: field },
    //     ]);
    //     break;
    //   }
    //   case 2: {
    //     setQuestionnaireDetails([
    //       ...questionnaireDetails,
    //       { checked: true, data: field },
    //     ]);
    //     break;
    //   }
    //   default:
    //     console.log('this never shoudl\'ve happened')
    // }
    setShowNewFieldForm(false);
  }

  function handleNewFieldFormClick() {
    setShowNewFieldForm(true);
  }

  //if (null === state) return <Spinner message={`Loading`} />;

  return (
    <Dialog scroll='paper' open={isOpen} maxWidth='lg' fullWidth>
      <DialogTitle>
        <Stepper activeStep={activeStep}>
          {null !== state && state.map(({ title }) =>
            <Step key={title}>
              <StepLabel>{title}</StepLabel>
            </Step>
          )}
        </Stepper>
      </DialogTitle>
      <DialogContent dividers key={activeStep} /*style={{background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))'}}*/>
        {
          null === state ? (
            <Spinner message={`Loading`} />
          ) : (
            <Grid container spacing={0}>
              <Grid item xs={state[activeStep].title === "Summary" ? 12 : 6}>
                <Box sx={{ width: "100%" }}>
                  <Fragment>
                    <Typography component={"div"} sx={{ mt: 2, mb: 1 }}>
                      {state[activeStep].title === "Summary" ? (
                        <Summary
                          studyDetails={state[0]}
                          individualDetails={state[1]}
                          questionnaireDetails={state[2]}
                        />
                      ) : (
                        <FormRenderer
                          checkboxes={true}
                          fields={state[activeStep].data}
                          showErrors={state[activeStep].showErrors}
                          handleValueChange={handleValueChange(activeStep)}
                          handleCheckboxChange={handleCheckboxChange(activeStep)}
                        />
    
                      
                      )}
                    </Typography>
                    {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {activeStep < (state.length - 1) && (
                        <Button
                          color="secondary"
                          startIcon={<Add />}
                          onClick={handleNewFieldFormClick}
                          sx={{ml: 'auto', mr: 'auto'}}
                        >
                          {`Add custom '${state[activeStep].title}' field`}
                        </Button>
                      )}
                      {showNewFieldForm && (
                        <NewFieldForm create={createNewField} cancel={cancelNewField} />
                      )}
                    </Box> */}
                  </Fragment>
                  <Alert {...alertState} onClose={() => setAlertState({ ...alertState, open: false})} />
                </Box>
              </Grid>
              <Grid item xs={state[activeStep].title === "Summary" ? 0 : 6}>
                <Box sx={{ width: "100%", height: '100%' }}>
                  <div style={{borderLeft: '1px black solid', height: '100%', paddingLeft: '1em'}}>
                    {
                      0 === activeStep
                      ? studyDetailsInfo
                      : 1 === activeStep
                      ? individualDetailsInfo
                      : 2 === activeStep
                      ? questionnaireDetailsInfo
                      : <></>
                    }
                  </div>
                </Box>
              </Grid>
            </Grid>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelNewStudy} sx={{mr: 'auto'}}>Cancel</Button>
        <Button variant='outlined' disabled={activeStep === 0} onClick={() => setActiveStep(activeStep -3)}>Back</Button>
        {
          null !== state && (
            (activeStep === state.length - 1)
            ? <Button onClick={handleFinish}>Finish</Button>
            : <Button onClick={handleNext(activeStep)}>Next</Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
}
