import { useReducer, useState, useMemo } from "react";
import { useQuery  } from '@tanstack/react-query';
import { InputLabel, Slider, Select, MenuItem } from "@mui/material";
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { NewStudyWizard, StudiesBrowser } from "./components";
import { Button, Grid } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { getStudies, getUsersByFilter } from "../../apis";
import { Spinner, StatelessPaginatedTable } from "../../components";
import { useNavigate } from "react-router-dom";
import useCredentials from "../../hooks/useCredentials";
import StudiesAdminTool from "./components/AdminTool";
import useDebug from "../../hooks/useDebug";

const columns = [
  {
    name: 'Study ID',
    field: 'id',
    mutator: (value) => value,
    validator: (value) => '',
  },
  {
    name: 'Study Name',
    field: 'name',
    mutator: (value) => !value ? `!!! Missing study-name !!!` : value,
    validator: (value) => '',
  },
  {
    name: 'Codename',
    field: 'codename',
    mutator: (value) => !value ? `!!! Missing codename !!!` : value,
    validator: (value) => '',
  },
]

const ROWS_PER_PAGE_OPTIONS = [10, 15, 20, 30];

export default function StudiesPage() {
  const inDebugMode = useDebug();
  const { id, groups } = useCredentials();
  const isAdmin = useMemo(
    () =>  0 < groups.filter(g => 'admin' === g.role).length,
    [groups],
  );
  const [groupIdFilter, setGroupIdFilter] = useState(0);
  const [userId, setUserId] = useState(id);
  const navigate = useNavigate();
  const [open, flip] = useReducer(s => !s, false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const { isLoading: studiesIsLoading, data: studiesData } = useQuery({
    queryKey: ['studies', userId, limit, offset],
    queryFn: getStudies(userId, limit, offset),
  });
  const { isLoading: usersIsLoading, data: usersData } = useQuery({
    queryKey: ['users', JSON.stringify(groupIdFilter)],
    queryFn: getUsersByFilter({groupId: groupIdFilter}),
    enabled: false || (0 !== groupIdFilter),
  })

  return (
    <>
      {inDebugMode && (<pre>
        userId: {JSON.stringify(userId)}
      </pre>
      )}
      <Grid container spacing={1}>
        {isAdmin && (
        <Grid item xs={12} md={5}>
          <StudiesAdminTool
            groups={groups}
            isLoading={!usersData && (0 !== groupIdFilter)}
            selectedGroup={groupIdFilter || 0}
            selectGroup={setGroupIdFilter}
            selectedUser={userId}
            selectUser={setUserId}
            userList={!!usersData ? usersData : []}
            selectMe={() => setUserId(id)}
          />
        </Grid>
        )}
        <Grid item xs={12} md={isAdmin ? 7 : 12}>
          <StudiesBrowser
            isLoading={studiesIsLoading}
            columns={columns}
            rows={!!studiesData ? studiesData[0] : []}
            rowsTotal={!!studiesData ? Number(studiesData[1][0].total) : 0}
            rowsPerPage={limit}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={offset / limit}
            setRowsPerPage={setLimit}
            setPage={pageNum => setOffset(limit * pageNum)}
            rowsOnClick={({ id }) => navigate(`${id}`)}
          />
        </Grid>
      </Grid>
      <Button
        style={{width:'100%', marginTop: '1em'}}
        onClick={flip}
      >
        Create New Study
      </Button>
      {open && (
        <NewStudyWizard isOpen={true} cancelNewStudy={flip} />
      )}
    </>
  );
}