import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function InputsList({
  selectedTemplate,
  selectedLanguage,
  templates,
  translatedInputs,
  setSelectedTemplate,
}) {
  // const freetextEntries =
  //   selectedTemplate.isNewTemplate
  //   ? templates.find(({ isNewTemplate }) => isNewTemplate).freetext
  //   : selectedTemplate.id && templates.find(({ id }) => id === selectedTemplate.id).freetext;
  const freetextEntries = selectedTemplate.freetext;

  const moveDown = (index) => () => {
    if (index < selectedTemplate.inputs.length - 1) {
      const inputs = [...selectedTemplate.inputs];
      const item = inputs.splice(index, 1)[0];
      inputs.splice(index + 1, 0, item);
      setSelectedTemplate({
        ...selectedTemplate,
        inputs,
      });
    }
  };
  const moveUp = (index) => () => {
    if (index > 0) {
      const inputs = [...selectedTemplate.inputs];
      const item = inputs.splice(index, 1)[0];
      inputs.splice(index - 1, 0, item);
      setSelectedTemplate({
        ...selectedTemplate,
        inputs,
      });
    }
  };
  const deleteInput = (index) => () => {
    const inputs = [...selectedTemplate.inputs];
    inputs.splice(index, 1);
    setSelectedTemplate({
      ...selectedTemplate,
      inputs,
    });
  };

  if (!selectedTemplate.id && !selectedTemplate.isNew) {
    return (
      <Card sx={{ width: '100%', height: 528, display: 'flex' }}>
        <CardContent style={{ margin: 'auto'}}>
          <Typography variant="h5" component="div">
            Select a template to list its inputs
          </Typography>
        </CardContent>
      </Card>
    );
  };
  
  return (
    <List sx={{ width: '100%', maxHeight: 540, overflowY: 'scroll' }} subheader={<li />} dense>
      {!!freetextEntries.length && (
        <ListSubheader>Standard inputs:</ListSubheader>
      )}
      {(!!selectedTemplate.id || selectedTemplate.inputs) &&
        selectedTemplate
        .inputs
        .map((input) => translatedInputs.find(({input_id, language}) => input_id === input && language === selectedLanguage))
        .map(( {translation, id}, index ) => (
          <ListItem key={`input_${id}`}>
            <ListItemText primary={translation} />
            {!!setSelectedTemplate && (
              <>
                <IconButton key={`input_${translation}_up`} onClick={moveUp(index)} aria-label="move up">
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton key={`input_${translation}_down`} onClick={moveDown(index)} aria-label="move down">
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton key={`input_${translation}_del`} onClick={deleteInput(index)} aria-label="delete">
                  <ClearIcon />
                </IconButton>
              </>
            )}
          </ListItem>
        ))
      }
      {!!freetextEntries.length && (
        <ListSubheader>Custom inputs:</ListSubheader>
      )}
      {!!freetextEntries.length && freetextEntries.map(({ entryId, entry }) => (
          <ListItem>
            <ListItemText key={`freetext_${entryId}`} style={{backgroundColor:'rgb(221, 255, 221)'}} primary={entry} />
          </ListItem>
      ))}
    </List>
  );
};