// TODO: make creator part of query
function getStudies(userId, limit, offset) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/studies/creator/${userId}?limit=${limit}&skip=${offset}`);
        if (response.ok) {
            return await response.json();
        }
        return [];
    }
}

function getStudyById(studyId) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/studies/${studyId}`);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
}

async function createStudy({ userId, groupId, study }) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/studies/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                groupId,
                study,
            }),
        },
    );
    if (response.ok) {
        return await response.json();
    }
    return null;
};

export {
    getStudies,
    getStudyById,
    createStudy,
}