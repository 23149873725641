import { useEffect, useState, useRef } from "react";
import UnauthenticatedApp from "./UnauthenticatedApp";
import AuthenticatedApp from "./AuthenticatedApp";
import { Buffer } from 'buffer';
import { UserContext } from "./context/user";
import { AppHeader } from "./context/appHeader";
import { DebugContext } from "./context/debug"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert } from './components';

const DEFAULT = 'Kreitler-Meaning-System v4.0';
const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#9c27b0',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
    },
    spacing: 8,
    direction: 'rtl',
    shape: {
      borderRadius: 4,
    },
    overrides: {
      MuiAppBar: {
        colorInherit: {
          backgroundColor: '#689f38',
          color: '#fff',
        },
      },
      MuiSwitch: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
      MuiButton: {
        root: {
          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          border: 0,
          borderRadius: 3,
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          color: 'white',
          height: 48,
          padding: '0 30px',
        },
      },
    },
    props: {
      MuiAppBar: {
        color: 'inherit',
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiList: {
        dense: true,
      },
      MuiMenuItem: {
        dense: true,
      },
      MuiTable: {
        size: 'small',
      },
      MuiButton: {
        size: 'small',
      },
      MuiButtonGroup: {
        size: 'small',
      },
      MuiCheckbox: {
        size: 'small',
      },
      MuiFab: {
        size: 'small',
      },
      MuiFormControl: {
        margin: 'dense',
        size: 'small',
      },
      MuiFormHelperText: {
        margin: 'dense',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiInputBase: {
        margin: 'dense',
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiRadio: {
        size: 'small',
      },
      MuiSwitch: {
        size: 'small',
      },
      MuiTextField: {
        margin: 'dense',
        size: 'small',
      },
      MuiTooltip: {
        arrow: true,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary'
        }
      }
    }
});
const U = {
  id: 1,
  name: 'Sergei Plotnikov',
  username: 'sergei.plotnikov',
  groups: [
    {
      id: 1,
      codename: 'TAU',
      role: 'admin',
      name: 'Tel-Aviv University',
    },
    {
      id: 2,
      codename: 'TAU-23A',
      role: 'admin',
      name: 'Tel-Aviv University, Autumn 2023',
    },
    {
      id: 3,
      codename: 'TAU-23B',
      role: 'admin',
      name: 'Tel-Aviv University, Spring 2024',
    },
  ]
};
export default function AppWrapper({ children }) {
    const [user, setUser] = useState(null);
    const [alertState, setAlertState] = useState({ message: '', severity: 'error', open: false });
    const [inDebugMode, setInDebugMode] = useState(false);
    const currentTimer = useRef();

    if (inDebugMode && (!window.location.href.endsWith('#debug') || window.location.href.endsWith('#nodebug')))
      setInDebugMode(false);
    if (!inDebugMode && (window.location.href.endsWith('#debug')))
      setInDebugMode(true);

    useEffect(() => {
        setUser(
          document.cookie.split('Authorization')[1]
          ? JSON.parse(Buffer.from(document.cookie.split('Authorization')[1].split(';')[0].split('Bearer%20')[1].split('.')[1], 'base64'))
          : null
        );
        startTimer();
        console.log('user changed');
        if (!user) stopTimer();
    }, [user?.id])

    const [appHeader, setAppHeader] = useState(DEFAULT);

    const startTimer = () => {
        currentTimer.current = setInterval(() => {
            console.log(`setting cookie: ${document.cookie}`)
            setUser(
                document.cookie.split('Authorization')[1]
                ? JSON.parse(Buffer.from(document.cookie.split('Authorization')[1].split(';')[0].split('Bearer%20')[1].split('.')[1], 'base64'))
                : null
            );
        }, 60 * 1000);
    };
    const stopTimer = () => {
        clearInterval(currentTimer.current);
    };

    const login = async (username, password) => {
      const result = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/v1/login`,
          {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  username,
                  password,
              }),
          }
      );
      if (200 !== result.status) {
        const response = await result.json();
        setAlertState({open: true, severity: 'error', message: `Failed to login: ${response.errorMsg}`});
      } else {
        setUser(
          document.cookie.split('Authorization')[1]
          ? JSON.parse(Buffer.from(document.cookie.split('Authorization')[1].split(';')[0].split('Bearer%20')[1].split('.')[1], 'base64'))
          : null
        );
      }


        // .then(() => {
          // setUser(
          //   document.cookie.split('Authorization')[1]
          //   ? JSON.parse(Buffer.from(document.cookie.split('Authorization')[1].split(';')[0].split('Bearer%20')[1].split('.')[1], 'base64'))
          //   : null
          // );
        // })
        // .catch(error => {
        //   console.log(error);
        //   setAlertState({open: true, severity: 'error', message: 'Failed to login'});
        // });
    };
    
    return (
      <DebugContext.Provider value={inDebugMode}>
        <UserContext.Provider value={user}>
            <ThemeProvider theme={theme}>
                <AppHeader.Provider value={ !!user ? [appHeader, setAppHeader] : [appHeader, () => {}]}>
                    {
                        user
                        ? <AuthenticatedApp user={user} />
                        : <>
                        <UnauthenticatedApp
                            login={login}
                        />
                        <Alert {...alertState} onClose={() => setAlertState({ ...alertState, open: false})} />
                        </>
                    }
                </AppHeader.Provider>
            </ThemeProvider>
        </UserContext.Provider>
      </DebugContext.Provider>
    );
};