import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import useDirection from "../../../../hooks/useDirection";

const MAX_FILE_SIZE = 10_000_000;

export default function AnswerEditDialog({ inputValue, answerText, cancel, save }) {
  const dir = useDirection();
  const [newText, setNewText] = useState(answerText || '');
  const [newImageFile, setNewImageFile] = useState(null);
  const [createImage] = [];
  const [deleteImage] = [];

  const isFileTooLarge = () => null !== newImageFile && newImageFile.size > MAX_FILE_SIZE;

  function handleSave() {
    // if (newImageFile) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(newImageFile);
    //   reader.onload = async function ({ target: { result }}) {
    //     const res = await createImage({
    //       variables: {
    //         record: { name: newImageFile.name, bindata: result },
    //       },
    //     });
    //     const newImageId = res.data.imageCreateOne.recordId;
    //     const newImage = {
    //       _id: newImageId,
    //       name: newImageFile.name,
    //       bindata: result,
    //     };
    //     deleteImage({ variables: { id: imageId } });
    //     const newInput = {
    //       ...input,
    //       answer: { ...input.answer, text: newText, image_id: newImageId },
    //     };
    //     save(newInput, newImage);
    //   };
    // } else {
    //   const newInput = { ...input, answer: { ...input.answer, text: newText } };
    //   save(newInput, null);
    // }
  }

  return (
    <Dialog open>
      <DialogTitle>
        <span style={{float: 'left', paddingRight:'1em'}}>{`Edit answer for input:`}</span>
        <span style={{float: 'ltr' === dir ? 'left' : 'right'}}>{inputValue}</span>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label={"Answer"}
          onChange={({target: {value}}) => setNewText(value)}
          value={newText}
          multiline
          dir={dir}
          rows={4}
          style={{width:'100%', marginBottom: '2em'}}
        />
        <MuiFileInput
          label={"Click to upload image file"}
          value={newImageFile}
          onChange={setNewImageFile}
          helperText={isFileTooLarge() ? "File size exceeds 10MB" : ""}
          error={isFileTooLarge()}
          style={{width:'100%'}}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancel}
        >
          cancel
        </Button>
        <Button
          disabled={isFileTooLarge()}
          onClick={() => save(newText)}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
}