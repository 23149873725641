import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Grid, InputLabel, Button } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from "@tanstack/react-query";
import useDirection from '../../../../../hooks/useDirection';
import CodingStatusStar from '../CodingStatusStar';
import { getSuggestion } from '../../../../../apis';

const srOptions = [
    {"value":"1","title":"Identical","additional":"Close","additional2":""},
    {"value":"2","title":"Opposite","additional":"Medium","additional2":""},
    {"value":"3","title":"Partial","additional":"Close","additional2":""},
    {"value":"4","title":"Modified by addition","additional":"Medium","additional2":""},
    {"value":"5","title":"Previous meaning value","additional":"Medium","additional2":""},
    {"value":"6","title":"Association","additional":"Medium","additional2":""},
    {"value":"7","title":"Unrelated","additional":"Distant","additional2":""},
    {"value":"8","title":"Verbal label","additional":"Distant","additional2":""},
    {"value":"9","title":"Grammatical variation","additional":"Close","additional2":""},
    {"value":"10","title":"Previous meaning values combined","additional":"Medium","additional2":""},
    {"value":"11","title":"Superordinate","additional":"Medium","additional2":""},
    {"value":"12a","title":"Synonym In original language","additional":"Close","additional2":""},
    {"value":"12b","title":"Synonym Translated in another language","additional":"Close","additional2":""},
    {"value":"12c","title":"Synonym Label in another medium","additional":"Close","additional2":""},
    {"value":"12d","title":"Synonym A different formulation of the same referent on the same level","additional":"Close","additional2":""},
    {"value":"13","title":"Replacement by implicit meaning value","additional":"Distant","additional2":""}
];

const refLvlOptions = [
    {"value":"1","title":"Identical","additional":"","additional2":""},
    {"value":"2","title":"One step away from input or previous referent","additional":"","additional2":""},
    {"value":"3","title":"Two steps away from input or previous referent","additional":"","additional2":""},
    {"value":"4","title":"Three steps away from input","additional":"","additional2":""},
    {"value":"5","title":"Four steps away from input","additional":"","additional2":""},
    {"value":"6","title":"Five steps away from input","additional":"","additional2":""},
];

const dimOptions = [
    {"value":"1","title":"Contextual allocation","additional":"","additional2":""},
    {"value":"2a","title":"Range of inclusion -Sub-classes","additional":"","additional2":""},
    {"value":"2b","title":"Range of inclusion -Parts","additional":"","additional2":""},
    {"value":"3","title":"Function, purpose & roles","additional":"","additional2":""},
    {"value":"4a","title":"Actions & potentialities for actions-By referent","additional":"","additional2":""},
    {"value":"4b","title":"Actions & potentialities for actions-To or with referent","additional":"","additional2":""},
    {"value":"5","title":"Manner of occurrence & operation","additional":"","additional2":""},
    {"value":"6","title":"Antecedents & causes","additional":"","additional2":""},
    {"value":"7","title":"Consequences & Results","additional":"","additional2":""},
    {"value":"8a","title":"Domain of application-As subject","additional":"","additional2":""},
    {"value":"8b","title":"Domain of application-As object","additional":"","additional2":""},
    {"value":"9","title":"Material","additional":"","additional2":""},
    {"value":"10","title":"Structure","additional":"","additional2":""},
    {"value":"11","title":"State & possible changes in it","additional":"","additional2":""},
    {"value":"12","title":"Weight & mass","additional":"","additional2":""},
    {"value":"13","title":"Size & dimensionality","additional":"","additional2":""},
    {"value":"14","title":"Quantity & number","additional":"","additional2":""},
    {"value":"15","title":"Location Qualities","additional":"","additional2":""},
    {"value":"16","title":"Temporal Qualities","additional":"","additional2":""},
    {"value":"17a","title":"Possessions and belongingness - by the referent","additional":"","additional2":""},
    {"value":"17b","title":"Possessions and belongingness- of the referent","additional":"","additional2":""},
    {"value":"18","title":"Development","additional":"","additional2":""},
    {"value":"19a","title":"Sensory Qualities-Of referent","additional":"This meaning dimension includes a listing of subcategories of the different senses/sensations: for special purposes they may also be grouped into","additional2":""},
    {"value":"19b","title":"Sensory Qualitiesc-By referent","additional":"","additional2":""},
    {"value":"20a","title":"Feelings & Emotions-Evoked by referent","additional":"For special purposes the responses in Feelings and Emotions may be grouped into ","additional2":""},
    {"value":"20b","title":"Feelings & Emotions-Felt by referent","additional":"","additional2":""},
    {"value":"21a","title":"Judgments& Evaluations-About referent","additional":"","additional2":""},
    {"value":"21b","title":"Judgments & Evaluations-By referent","additional":"","additional2":""},
    {"value":"22a","title":"Cognitive acts & qualities-Evoked by referent","additional":"","additional2":""},
    {"value":"22b","title":"Cognitive acts & qualities-Of/by referent","additional":"","additional2":""},
];

const trOptions = [
    {"value":"1a","title":"Attributive: Qualities to substance","additional":"Lexical mode","additional2":""},
    {"value":"1b","title":"Attributive: Actions to agent","additional":"Lexical mode","additional2":""},
    {"value":"2a","title":"Comparative: Similarity","additional":"Lexical mode","additional2":""},
    {"value":"2b","title":"Comparative: Difference","additional":"Lexical mode","additional2":""},
    {"value":"2c","title":"Comparative: Complementary","additional":"Lexical mode","additional2":""},
    {"value":"2d","title":"Comparative: Relational","additional":"Lexical mode","additional2":""},
    {"value":"3a","title":"Exemplifying-Illustrative: Instance","additional":"Personal mode","additional2":""},
    {"value":"3b","title":"Exemplifying-Illustrative: Situation","additional":"Personal mode","additional2":""},
    {"value":"3c","title":"Exemplifying-Illustrative: Scene","additional":"Personal mode","additional2":""},
    {"value":"4a","title":"Metaphoric-Symbolic: Interpretation","additional":"Personal mode","additional2":""},
    {"value":"4b","title":"Metaphoric-Symbolic: Conventional Metaphor ","additional":"Personal mode","additional2":""},
    {"value":"4c","title":"Metaphoric-Symbolic: Original Metaphor","additional":"Personal mode","additional2":""},
    {"value":"4d","title":"Metaphoric-Symbolic: Symbol","additional":"Personal mode","additional2":""},
];

const frOptions = [
    {"value":"1a","title":"Propositional: Positive","additional":"","additional2":""},
    {"value":"1b","title":"Propositional: Negative","additional":"","additional2":""},
    {"value":"2a","title":"Partial: Positive","additional":"","additional2":""},
    {"value":"2b","title":"Partial: Negative","additional":"","additional2":""},
    {"value":"3a","title":"Universal: Positive","additional":"","additional2":""},
    {"value":"3b","title":"Universal: Negative","additional":"","additional2":""},
    {"value":"4a","title":"Conjunctive: Positive","additional":"","additional2":""},
    {"value":"4b","title":"Conjunctive: Negative","additional":"","additional2":""},
    {"value":"5a","title":"Disjunctive: Positive","additional":"","additional2":""},
    {"value":"5b","title":"Disjunctive: Negative","additional":"","additional2":""},
    {"value":"6a","title":"Normative: Positive","additional":"","additional2":""},
    {"value":"6b","title":"Normative: Negative","additional":"","additional2":""},
    {"value":"7a","title":"Questioning: Positive","additional":"","additional2":""},
    {"value":"7b","title":"Questioning: Negative","additional":"","additional2":""},
    {"value":"8a","title":"Desired: Positive","additional":"","additional2":""},
    {"value":"8b","title":"Desired: Negative","additional":"","additional2":""},
];

const feOptions = [
    {"value":"1a","title":"Verbal: actual verbal response","additional":"1.Verbal","additional2":""},
    {"value":"1b","title":"Verbal: verbal title of verbal response","additional":"1.Verbal","additional2":""},
    {"value":"1c","title":"Verbal: using available verbal materials","additional":"1.Verbal","additional2":""},
    {"value":"2a","title":"Visual,graphic: Actual visual material","additional":"2.Visual,graphic","additional2":""},
    {"value":"2b","title":"Visual,graphic: Verbal title of visual response","additional":"2.Visual,graphic","additional2":""},
    {"value":"2c","title":"Visual,graphic: Using available visual materials","additional":"2.Visual,graphic","additional2":""},
    {"value":"3a","title":"Motoric,motional: Actual enactment of movements, acts etc.","additional":"3.Motoric,motional","additional2":""},
    {"value":"3b","title":"Motoric,motional: Verbal title of motoric-motional response","additional":"3.Motoric,motional","additional2":""},
    {"value":"3c","title":"Motoric,motional: Using available materials of motoric expressions","additional":"3.Motoric,motional","additional2":""},
    {"value":"4a","title":"Auditory(vocal,tonal): Actual production of auditory materials","additional":"4.Auditory","additional2":""},
    {"value":"4b","title":"Auditory(vocal,tonal): Verbal title of auditory response","additional":"4.Auditory","additional2":""},
    {"value":"4c","title":"Auditory(vocal,tonal): using available auditory materials","additional":"4.Auditory","additional2":""},
    {"value":"5a","title":"Denotative: Actual presentation of object, situation etc.","additional":"5.Denotative","additional2":""},
    {"value":"5b","title":"Denotative: Verbals title of denotative response","additional":"5.Denotative","additional2":""},
];

const ssOptions = [
    {"value":"1","title":"General, visual","additional":"Looks like, appear as","additional2":""},
    {"value":"2","title":"Color","additional":"Colors, e.g. red, greenish, light blue","additional2":""},
    {"value":"3","title":"Form and shape","additional":"Round, oblong, in the form of a square, in the shape of an octahedron","additional2":""},
    {"value":"4","title":"Gustatory, taste qualities","additional":"Tastes, such as bitter, salty, sweet, sour, bland, tasteless","additional2":""},
    {"value":"5","title":"Auditory, sound qualities","additional":"Refers to tones, tempo, volume, timber, pitch, rhythm, harmony, etc. of musical, vocal or other types of sounds","additional2":""},
    {"value":"6","title":"Olfactory, smell qualities","additional":"Odors such as perfume, fragrance, scent of mint, smell of rotten stuff","additional2":""},
    {"value":"7","title":"Tactual-kinesthetic","additional":"Qualities of external surfaces, such as elasticity, smoothness, roughness, stickiness, furry, hard, soft, resilient, crunchy, powdery","additional2":""},
    {"value":"8","title":"Temperature, thermal qualities","additional":"hot, cold, cool, warm, burning, scalding, freezing","additional2":""},
    {"value":"9","title":"Internal sensations","additional":"Vibration, spasms, nausea, muscular sensations, tension, hunger, thirst, internal pressure, sexuality","additional2":""},
    {"value":"10","title":"General sensations","additional":"For example, has sensory qualities","additional2":""},
    {"value":"11","title":"Pain","additional":"For example, it hurts, it is numb, painful","additional2":""},
    {"value":"12","title":"Humidity and dryness","additional":"For example, moist, wet, dry, humid","additional2":""},
    {"value":"13","title":"Skin sensations","additional":"Cutaneous sensations, such as prickly, tingling, tickling, itching, trembling, vibratory, greasy, silky","additional2":""},
    {"value":"14","title":"Light or brightness sensations","additional":"brightness, contrasts, shading, illuminated, darkened, pale, dark, transparent, hazy, lucid, turbid, cloudy, diaphanous, sparkling, brilliant, limpid, vitreous, translucent, dull, clear","additional2":""},
];

const mmOptions = [
    {"value":"1.-1.a","title":"Generality/ specificity: General, nonspecific","additional":"e.g., something, anything, whatever, of different kinds","additional2":"1.  Modulation in the meaning of meaning values"},
    {"value":"1.-1.b","title":"Generality/ specificity: Certain, specific ","additional":" e.g., only, specifically, a particular....","additional2":""},
    {"value":"1.-2.a","title":"Change of meaning - simple literal meaning or non-literal meaning: No change -  literal meaning","additional":"e.g., in simple terms, literally","additional2":""},
    {"value":"1.-2.b","title":"Change of meaning - simple literal meaning or non-literal meaning: Change - nonliteral meaning","additional":"e.g., metaphorically, using imagination, non-literally, figuratively","additional2":""},
    {"value":"1.-3.a","title":"Breadth of meaning: Broad, extended meaning","additional":"e.g., in a broader sense, in the full meaning  ","additional2":""},
    {"value":"1.-3.b","title":"Breadth of meaning: Narrow, limited meaning","additional":"e.g., in a narrower sense, in a specific sense of the word, partial meaning  ","additional2":""},
    {"value":"1.-4.a","title":"Relation to reality: Theoretical, not in reality","additional":"e.g. theoretically, in theory  ","additional2":""},
    {"value":"1.-4.b","title":"Relation to reality: Actual, real","additional":"e.g., in an applied sense, practically, in reality, actually","additional2":""},
    {"value":"1.-5.a","title":"Depth of meaning: Superficial","additional":"e.g., superficially, externally, on paper  ","additional2":""},
    {"value":"1.-5.b","title":"Depth of meaning: Deep","additional":"e.g., in a deeper sense  ","additional2":""},
    {"value":"1.-6.a","title":"Objectivity/ subjectivity: Objective","additional":"e.g., objectively, in view of the facts  ","additional2":""},
    {"value":"1.-6.b","title":"Objectivity/ subjectivity: Subjective","additional":"e.g., subjectively, for the point of view of a single person  ","additional2":""},
    {"value":"1.-7.a","title":"Emphasis: Strong emphasis","additional":"e.g., first and foremost, essentially, especially, primarily, I emphasize, [underlining, typing in bold or a special color, using exclamation sign]  ","additional2":""},
    {"value":"1.-7.b","title":"Emphasis: Weak emphasis","additional":"e.g., in parenthesis, in the background, as second thought, as a marginal remark  ","additional2":""},
    {"value":"1.-8.a","title":"Seriousness of meaning: Serious in a regular sense","additional":"e.g., generally, regularly, seriously  ","additional2":""},
    {"value":"1.-8.b","title":"Seriousness of meaning: Not quite serious","additional":"e.g., as a joke, ironically, not quite seriously, as a rhetorical question, jokingly, playfully. In jest, facetiously  ","additional2":""},
    {"value":"1.-9.a","title":"Clarifying meaning: By repeating or summarizing","additional":"e.g., more specifically, more precisely, with greater precision, for the sake of clarity, namely, in sum  ","additional2":""},
    {"value":"1.-9.b","title":"Clarifying meaning: By using a different expression (not summarizing)","additional":"e.g., I mean, actually, in truth, the bottom line  ","additional2":""},
    {"value":"1.-10.a","title":"Citing origin of response: Mentioned source other than the speaker","additional":"e.g. mentions  idiom, citation from a known identified source, quotes a certain person such as neighbor, parent  ","additional2":""},
    {"value":"1.-10.b","title":"Citing origin of response: Mentions oneself as a source, i.e., cites oneself or refers to ones previous response","additional":"e.g., as I said earlier, see my response to previous item  ","additional2":""},
    {"value":"2.-1.a","title":"Surprise: Not surprising","additional":"e.g., typically, not surprisingly, as hypothesized,  understandably, as expected, appropriately, predictably","additional2":"2.  Expressing attitude to response contents  "},
    {"value":"2.-1.b","title":"Surprise: Surprising","additional":"e.g., oddly, remarkably, strangely, unexpectedly, contrary to the expected  ","additional2":""},
    {"value":"2.-2.a","title":"Veridicality, correctness: Correctly","additional":"e.g., correctly, justly, sensibly, reasonably  ","additional2":""},
    {"value":"2.-2.b","title":"Veridicality, correctness: Incorrectly","additional":"e.g., unwisely, wrongly, unjustly, incorrectly, unreasonably  ","additional2":""},
    {"value":"2.-3.a","title":"Characteristic/Extraordinary: Regular","additional":"","additional2":""},
    {"value":"2.-3.b","title":"Characteristic/Extraordinary: Extraordinary","additional":"","additional2":""},
    {"value":"2.-4.a","title":"Positiveness of the feeling evoked by the meaning value: Satisfying, causing joy","additional":"e.g., fortunately, thanks God, luckily, happily","additional2":""},
    {"value":"2.-4.b","title":"Positiveness of the feeling evoked by the meaning value: Saddening, disappointing","additional":"e.g., lamentably, alas, by bad luck,  unfortunately","additional2":""},
    {"value":"3.-1.a","title":"Degree of confidence: Expressing confidence","additional":"e.g., undoubtedly, evidently, obviously, surely","additional2":" 3. Confidence in the response  "},
    {"value":"3.-1.b","title":"Degree of confidence: Expressing doubt or reservation","additional":"e.g., to the best of my information, maybe, perhaps, apparently, it may be assumed, probably, it seems that  ","additional2":""},
    {"value":"4.-1.a","title":"General attribution: Attributing to oneself","additional":"e.g., to my mind, I think, it seems to me","additional2":"4. Attributing response to oneself or others"},
    {"value":"4.-1.b","title":"General attribution: Attributing to others","additional":"e.g., Everyone thinks, it is generally assumed, it is common knowledge  ","additional2":""},
    {"value":"4.-2.a","title":"Specificity of attribution: Specific attribution to oneself","additional":"i.e., tells an event or experience that happened to him/her e.g. I remember that.., once it happened to me...  ","additional2":""},
    {"value":"4.-2.b","title":"Specificity of attribution: Attributing to specific others","additional":"i.e., mentions a specific other person or group to whom a particular event or experience happened, e.g., my grandma.., my mother... etc.  ","additional2":""},
    {"value":"5.-1.a","title":"Separating or identifying interpersonal and personal meanings\\Identifying interpersonal meaning","additional":"e.g., uses a heading (such as, lexical meaning), spacing, numbers, underlining or another marker to identify the interpersonal-lexical meaning and separate it from the personal-subjective meaning","additional2":"5. Organizing responses to an input "},
    {"value":"5.-1.b","title":"Separating or identifying interpersonal and personal meanings: Identifying personal meaning","additional":"e.g., uses a heading (such as, personal meaning) or another describing title (such as \"for me\"), spacing, number, underlining or another marker to identify the personal meaning and separate it from the interpersonal meaning  ","additional2":""},
    {"value":"5.-2.a","title":"Identifying different meanings: Not separating different meanings","additional":"","additional2":""},
    {"value":"5.-2.b","title":"Identifying different meanings: Separating different meanings","additional":"e.g., separates different meanings or readings of the same input or different versions of one meaning by numbers, spacing, separator etc.","additional2":""},
    {"value":"6.-1.a","title":"Evaluating the task:Task is difficult","additional":"e.g., it is difficult to express/describe/explain  ","additional2":"6. Evaluating task or response  "},
    {"value":"6.-1.b","title":"Evaluating the task: Task is easy","additional":"e.g., it is easy to communicate/express/explain  ","additional2":""},
    {"value":"6.-2.a","title":"Evaluating ones response: Positive evaluation","additional":"e.g., the response is precise, I managed to express it well  ","additional2":""},
    {"value":"6.-2.b","title":"Evaluating ones response: Negative, not positive evaluation","additional":"e.g., the drawing is bad, the response is not precise, the expression I used is not elegant  ","additional2":""},
    {"value":"7.-1.a","title":"Unclarity: Unclarity in regard to the meaning value","additional":"e.g., uses general terms, such as \"such as\", \"like\", Form of Relation may be missing  ","additional2":"7. Incomplete response  "},
    {"value":"7.-1.b","title":"Unclarity: Unclarity in regard to the referent","additional":"e.g., it is unclear to what the response refers, incomplete reference to referent (such as, \"it\", \"that something\")  ","additional2":""},
    {"value":"7.-2.a","title":"Missing elements: Missing meaning value (but there is referent)","additional":"e.g., when the input is \"Street, the response is ...\"Street... Street... it \"  ","additional2":""},
    {"value":"7.-2.b","title":"Missing elements: Missing referent (but there is meaning value)","additional":"e.g., there is no referent but there may be a meaning value, such as \"dirty\".  ","additional2":""},
    {"value":"7.-3.a","title":"Inability to express (expressive or conceptual inability): Inability to express the meaning value","additional":"e.g., the respondent says clearly \"I cannot say what X is\" and the impression is that the respondent knows the meaning but cannot express or communicate it  ","additional2":""},
    {"value":"7.-3.b","title":"Inability to express (expressive or conceptual inability): Inability to express the referent","additional":"e.g., the respondent says clearly \"I cannot say/explain what this thing is\" and the impression is that the respondent knows what the referent is but cannot express it  ","additional2":""},
    {"value":"7.-4.a","title":"Explicitly stated missing information: No response because has nothing to say","additional":"e.g., says \"I have nothing to say\", \"tells me nothing\". The impression is that he/she identifies the referent in some form but it has no meaning for him, does not know what to say about it  ","additional2":""},
    {"value":"7.-4.b","title":"Explicitly stated missing information: No response because does not understand input","additional":"e.g., says \"I dont know what X is, I have no idea, I dont understand what it is, I have never heard a word like X, or I have never seen a picture like this","additional2":""},
    {"value":"7.-5","title":"Missing response without any explanation","additional":"","additional2":""}
];

export default function CodingsTableRow({
    coding,
    onChange,
    multipleChange,
    expanded,
    onExpand,
    moveUp,
    moveDown,
    deleteRow,
    insert,
    editReference,
    language,
    displaySuggestions,
    autoApplySuggestions,
}) {
    const dir = useDirection();
    const { isLoading, data, refetch } = useQuery({
        queryKey: ['references', language, coding.referent, coding.meaningVal],
        queryFn: getSuggestion(language, coding.referent, coding.meaningVal),
        onSuccess: () => console.log('useQuery 2024-05-03T17:04:53.357Z'),
        enabled: false,
    });

    const requestTimer = useRef(); 

    const [displayDelete, setDisplayDelete] = useState(false);
    useEffect(() => {
        if(displayDelete) {
            setTimeout(() => setDisplayDelete(false), 2000);
        }
    }, [displayDelete]);

    const handleReferentUpdate = ({target: {value}}) => {
        clearTimeout(requestTimer.current);
        requestTimer.current = setTimeout(
            () => {
                if ('' !== value.trim() && '' !== coding.meaningVal.trim()) {
                    // console.clear();
                    // console.log({referent: value.trim(), meaningVal: coding.meaningVal.trim()});
                    refetch();
                }
            },
            500,
        )
        onChange('referent')({target: {value}});
    };

    const handleMeaningValUpdate = ({target: {value}}) => {
        clearTimeout(requestTimer.current);
        requestTimer.current = setTimeout(
            () => {
                if ('' !== value.trim() && '' !== coding.referent.trim()) {
                    // console.clear();
                    // console.log({referent: coding.referent.trim(), meaningVal: value.trim()});
                    refetch();
                }
            },
            500,
        )
        onChange('meaningVal')({target: {value}});
    };

    const handleApplySuggestion = () => {
        const {meaningVal, referent, id, ...rest} = data;
        multipleChange({
            ...rest,
            reference: id,
        });
    }

    return (
        <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onExpand}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
            {coding.referent}
        </TableCell>
        <TableCell>
            {coding.meaningVal}
        </TableCell>
        <TableCell>
            {coding.sr}
        </TableCell>
        <TableCell>
            {coding.refLvl}
        </TableCell>
        <TableCell >
            {coding.dim}
        </TableCell>
        <TableCell>
            {coding.tr}
        </TableCell>
        <TableCell>
            {coding.fr}
        </TableCell>
        <TableCell>
            {coding.fe}
        </TableCell>
        <TableCell>
            {coding.ss}
        </TableCell>
        <TableCell>
            {coding.mm}
        </TableCell>
        <TableCell>
            <CodingStatusStar
                allowEdit={!!editReference}
                edit={editReference}
                reference={coding.reference}
            />
        </TableCell>
        <TableCell>
          <ButtonGroup aria-label="Basic button group" style={{marginRight:'auto', marginLeft:'auto', display:'table'}}>
            <Tooltip title="Move up" placement='top-start'>
            <IconButton onClick={moveUp} color="primary" ><ArrowUpwardIcon /></IconButton>
            </Tooltip>
            <Tooltip title="Add new coding below this one" placement='top-start'>
            <IconButton onClick={insert} color="primary" ><PlusOneIcon /></IconButton>
            </Tooltip>
            <Tooltip title="Move down" placement='top-start'>
            <IconButton onClick={moveDown} color="primary" ><ArrowDownwardIcon /></IconButton>
            </Tooltip>
            {!displayDelete && (
                <Tooltip title="Delete coding" placement='top-start'>
                <IconButton onClick={() => setDisplayDelete(true)} color="secondary" ><DeleteForeverIcon /></IconButton>
                </Tooltip>
            )}
            {displayDelete && (
                <Button onClick={deleteRow} color='secondary'>Confirm deletion</Button>
            )}
          </ButtonGroup>
        </TableCell>
        </TableRow>
        <TableRow>
            <TableCell />
            <TableCell colSpan={12}>
            <Collapse in={expanded} timeout={'auto'} unmountOnExit>
            {/* <button onClick={refetch}>refetch</button> */}
            <Grid container spacing={2} style={{paddingTop:'1em'}}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth error={false}>
                            <InputLabel>Referent</InputLabel>
                            <OutlinedInput
                                dir={dir}
                                disabled={null !== coding.reference}
                                label={'Referent'}
                                value={coding.referent}
                                onChange={handleReferentUpdate}
                                sx={{width:'100%'}}
                            />
                            <FormHelperText>{` `}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={false}>
                            <InputLabel>Meaning Val</InputLabel>
                            <OutlinedInput
                                dir={dir}
                                disabled={null !== coding.reference}
                                label={'Meaning Val'}
                                value={coding.meaningVal}
                                onChange={handleMeaningValUpdate}
                                sx={{width:'100%'}}
                            />
                            <FormHelperText>{` `}</FormHelperText>
                        </FormControl>
                        {!!displaySuggestions && null === coding.reference && (
                        <Button
                            style={{width:'100%'}}
                            disabled={!data || isLoading}
                            onClick={handleApplySuggestion}
                        >
                            Apply Suggestion
                        </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                            options={srOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.sr || '', title: coding.sr ? srOptions.find((option) => option.value === coding.sr).title : ''}}
                            onChange={(_, value) => onChange('sr')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.sr && data.sr !== coding.sr}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.sr && data.sr !== coding.sr ? `Suggestion: ${data.sr} - ${srOptions.find((option) => option.value === data.sr).title}` : ''}
                                    label="SR"
                                    variant="standard"
                                />
                            )}
                            onClea
                        />
                        <Autocomplete
                            options={refLvlOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.refLvl, title: coding.refLvl ? refLvlOptions.find((option) => option.value === coding.refLvl).title : ''}}
                            onChange={(_, value) => onChange('refLvl')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.refLvl && data.refLvl !== coding.refLvl}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.refLvl && data.refLvl !== coding.refLvl ? `Suggestion: ${data.refLvl} - ${refLvlOptions.find((option) => option.value === data.refLvl).title}` : ''}
                                    label="refLvl"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={dimOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.dim || '', title: coding.dim ? dimOptions.find((option) => option.value === coding.dim).title : ''}}
                            onChange={(_, value) => onChange('dim')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.dim && data.dim !== coding.dim}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.dim && data.dim !== coding.dim ? `Suggestion: ${data.dim} - ${dimOptions.find((option) => option.value === data.dim).title}` : ''}
                                    label="DIM"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={trOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.tr, title: coding.tr ? trOptions.find((option) => option.value === coding.tr).title : ''}}
                            onChange={(_, value) => onChange('tr')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.tr && data.tr !== coding.tr}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.tr && data.tr !== coding.tr ? `Suggestion: ${data.tr} - ${trOptions.find((option) => option.value === data.tr).title}` : ''}
                                    label="TR"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={frOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.fr || '', title: coding.fr ? frOptions.find((option) => option.value === coding.fr).title : ''}}
                            onChange={(_, value) => onChange('fr')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.fr && data.fr !== coding.fr}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.fr && data.fr !== coding.fr ? `Suggestion: ${data.fr} - ${frOptions.find((option) => option.value === data.fr).title}` : ''}
                                    label="FR"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={feOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.fe || '', title: coding.fe ? feOptions.find((option) => option.value === coding.fe).title : ''}}
                            onChange={(_, value) => onChange('fe')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.fe && data.fe !== coding.fe}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.fe && data.fe !== coding.fe ? `Suggestion: ${data.fe} - ${feOptions.find((option) => option.value === data.fe).title}` : ''}
                                    label="FE"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={ssOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.ss || '', title: coding.ss ? ssOptions.find((option) => option.value === coding.ss).title : ''}}
                            onChange={(_, value) => onChange('ss')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.ss && data.ss !== coding.ss}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.ss && data.ss !== coding.ss ? `Suggestion: ${data.ss} - ${ssOptions.find((option) => option.value === data.ss).title}` : ''}
                                    label="SS"
                                    variant="standard"
                                />
                            )}
                        />
                        <Autocomplete
                            options={mmOptions}
                            disabled={null !== coding.reference}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={{value: coding.mm || '', title: coding.mm ? mmOptions.find((option) => option.value === coding.mm).title : ''}}
                            onChange={(_, value) => onChange('mm')({target: {value: value?.value}})}
                            getOptionLabel={({ value, title }) => `${value}${'' === value ? '' : ' - '}${title}`}
                            autoHighlight
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={displaySuggestions && null === coding.reference && !!data && !!data.mm && data.mm !== coding.mm}
                                    helperText={displaySuggestions && null === coding.reference && !!data && !!data.mm && data.mm !== coding.mm ? `Suggestion: ${data.mm} - ${mmOptions.find((option) => option.value === data.mm).title}` : ''}
                                    label="MM"
                                    variant="standard"
                                />
                            )}
                        />                        
                    </Grid>
                </Grid>
            </Collapse>
            </TableCell>
        </TableRow>
        </>
    );
};