import Typography from '@mui/material/Typography';
import AppBreadcrumbs from "../components/Breadcrumbs";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Grid } from '@mui/material';

export default function DevPage() {
    return (
        <>
            <AppBreadcrumbs />
            <Typography variant='h4'>About the developer</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    {/* <Typography variant='h4'>CV</Typography> */}
                    <Typography variant='h5'>Ofir Tzabari</Typography>
                    <Typography variant='body1'>
                        <Typography style={{fontWeight:'bold'}} variant='span'>Email:&nbsp;</Typography><a href="mailto:ofirthabar@gmail.com">ofirthabar@gmail.com</a><br />
                        <Typography style={{fontWeight:'bold'}} variant='span'>Phone:</Typography>&nbsp;052-4880233
                        <Typography variant="body1" gutterBottom>
                        I am an experienced freelance Fullstack developer looking for a QA position. I am a self-learner, ambitious and very independent developer. Looking for opportunities to improve my craft and learn new skills.
                        </Typography>
                        <Typography variant='h5'>Experience</Typography>
                        <ul>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2022-2024:</Typography> Freelance developer for a Tel-Aviv University project https://kreitlermeaningsystem.com/dev. I am the single developer on this project. Backend consists of an Experss.js app with JavaScript and MariaDB. Frontend is a React.js app with ReactQuery and MUI components.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2019-2021:</Typography> Freelance developer at webXP. I developed websites using PHP with Laravel and JavaScript, with MySQL as the database.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2016-2017:</Typography> Repair technician at a smartphone repair-lab.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2010-2016:</Typography> Restaurant co-owner.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2009-2010:</Typography> Web-developer at MediaTech.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2008-2009:</Typography> Teaching assistant at Rupin Academic Center, teaching the C language.</li>
                        </ul>
                        <Typography variant='h5'>Skills</Typography>
                        <ul>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>Backend:</Typography> PHP + Laravel, JavaScript, Node.js, Express.js</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>Frontend:</Typography> JavaScript, React.js, ReactQuery, <a href="https://mui.com" target="_blank">MUI React Components</a>, jQuery, HTML + CSS</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>Databases:</Typography> MySQL, MariaDB, MongoDB</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>Tools:</Typography> Visual Studio Code, git, Nginx, Apache</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>Environments:</Typography> Windows and limited experience with Ubuntu Server and CentOS.</li>
                        </ul>
                        <Typography variant='h5'>Education</Typography>
                        <ul>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2018-2019:</Typography> A 650 hours Fullstack developer course at HackerU institute.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2004-2007:</Typography> Encrypted communication team-lead at Teleprocessing Corps.</li>
                            <li><Typography style={{fontWeight:'bold'}} variant='span'>2002-2004:</Typography> Technical Engineer, grades 13-14. Embedded development using microcontrollers (8086, 8051, PIC18) and the C language.</li>
                        </ul>
                    </Typography>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <Typography variant='h4'>
                        About this website
                    </Typography>
                </Grid> */}
            </Grid>
        </>
    )
}