import { useMemo, useState } from "react";
import {
  getStudyById,
  getQuestionnairesByStudyId,
  getResourceConfig,
} from "../../../../apis";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useQuery  } from '@tanstack/react-query';
import NewQuestionnaireWizard from "./NewQuestionnaireWizard";
import StatefulPaginatedTable from "../../../../components/StatefulPaginatedTable";
import useAppHeader from '../../../../hooks/useAppHeader';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Spinner, SplitButton } from "../../../../components";
import EditDetailsDialog from "./EditDetails";

const studyDetailsExplanantion = (
  <div>
    <Typography variant="h6">Edit study details</Typography>
    <Typography>Edit study a sdf as fs fasf adsf asdf asdf dasfasd fsd f etails</Typography>
  </div>
);

const individualDetailsExplanantion = (
  <div>
    <h2>Edit individual details</h2>
  </div>
);

const questionnaireDetailsExplanantion = (
  <div>
    <h2>Edit questionnaire details</h2>
  </div>
);

const setLayout = (field) => ({layout: {}, data: {...field}});

const setDefaults = (field) => {
  switch (field.data.config.type) {
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      return {
        ...field,
        layout: {
          ...field.layout,
          value: field.data.value || '',
        },
      };
    case 'dropdown':
      return {
        ...field,
        layout: {
          ...field.layout,
          value:
            field.data.value
            || {
              title:
                field.data.is_required 
                ? ''
                : field.data.is_defaultable
                ? 'NO DEFAULT VALUE SELECTED'
                : 'DEFAULT VALUE CANNOT BE SELECTED'
              ,
              value: 0,
            },
          options:
            field.data.is_required
            ? [...field.data.config.options]
            : field.data.is_defaultable
            ? [{title:'NO DEFAULT VALUE SELECTED', value: 0}, ...field.data.config.options]
            : [{title:'DEFAULT VALUE CANNOT BE SELECTED', value: 0}, ...field.data.config.options]
        },
      };
    default:
      return field;
  }
}

const setDisabled = (field) => ({
  ...field, 
  layout: {
    ...field.layout, 
    disabled: !!field.data.default_value || !field.data.is_defaultable,
  },
});

const setValidity = (field) => {
  let validator;
  switch (field.data.config.type) {
    case 'checkbox':
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: '',
          validator: () => true,
        },
      };
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      validator = (newValue) => !(field.data.is_required && String(newValue).trim() === '') ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value),
          validator,
        },
      };
    case 'dropdown':
      validator = (newValue) => !(field.data.is_required && newValue === 0) ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value.value),
          validator,
        },
      };
    default:
      return field;
  }
}

const setCheckboxes = (field) => {
  return {
    ...field,
    layout: {
      ...field.layout,
      isChecked: field.data.is_default || field.data.is_required,
      isCheckable: !field.data.is_required,
    },
  }
}

const ROWS_PER_PAGE_OPTIONS = [10, 15, 20, 30];


export default function StudyDisplay() {
  // const [header, setHeader] = useAppHeader();
  const [sortDir, setSortDir] = useState({columnName:'dummy', direction:'asc'});
  const columns = [
    {
      name: 'Questionnaire Codename',
      field: 'codename',
      mutator: (value) => value,
      validator: (value) => '',
    },
    {
      name: 'Externl ID',
      field: 'external_id',
      mutator: (v) => !!v && v.value ? v.value : '',
      validator: (value) => '',
    },
  ];
  const sortByColumn = (name) => () => {
    if (null === sortDir || sortDir.columnName !== name) {
      setSortDir({columnName: name, direction: 'asc'})
    } else {
      setSortDir({...sortDir, direction: 'asc' === sortDir.direction ? 'desc' : 'asc'})
    }
  }
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [isNewQuestionnare, setIsNewQuestionnaire] = useState(false);
  const [tab, setTab] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const { isLoading: isLoadingStudy, data: dataStudy } = useQuery({
    queryKey: ['study', studyId],
    queryFn: getStudyById(studyId),
  });
  const { isLoading: isLoadingQuestionnaires, data: questionnairesData } = useQuery({
    queryKey: ['questionnairesByStudyId', studyId],
    queryFn: getQuestionnairesByStudyId(studyId),
  });
  const sortedData = useMemo(
    () => {
      if ('dummy' === sortDir.columnName) return questionnairesData;
      const dir = 'asc' === sortDir.direction ? 1 : -1;
      const result = [...questionnairesData];
      result.sort(
        (a, b) => (a[sortDir.columnName] > b[sortDir.columnName])  ? dir * 1 : dir * -1
      );
      return result;
    },
    [questionnairesData, sortDir]
  )
  const { isLoading: isLoadingSettings, data: dataSettings } = useQuery({
    queryKey: ['config', 'study'],
    queryFn: getResourceConfig('study', dataStudy?.study.group_id),
    enabled: !!dataStudy,
  });

  const handleEditStudyDetails = () => {
    setEditDetails({
      title: 'Editing study-details',
      explanation: studyDetailsExplanantion,
      data: {
        fields: structuredClone(dataStudy.studyDetails).map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes),
        checkboxes: false,
      },
    })
  }

  const handleEditIndividualDetails = () => {
    debugger;
    setEditDetails({
      title: 'Editing individual-details',
      explanation: individualDetailsExplanantion,
      data: {
        fields: [...structuredClone(dataStudy.individualDetails), ...dataSettings.individualDetails].map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes),
        checkboxes: true,
      },
    })
  }

  const handleEditQuestinnaireDetails = () => {
    setEditDetails({
      title: 'Editing questionnaire-details',
      explanation: questionnaireDetailsExplanantion,
      data: {
        fields: structuredClone(dataStudy.questionnaireDetails).map(setLayout).map(setDefaults).map(setValidity).map(setCheckboxes),
        checkboxes: true,
      },
    })
  }

  const handleDetailValueChange = (index) => (newValue) => {
    setEditDetails({
      ...editDetails,
      data: {
        ...editDetails.data,
        fields: editDetails.data.fields.map(
          (field, i) => {
            if (i === index) {
              return {
                ...field,
                layout: {
                  ...field.layout,
                  value: newValue,
                },
              };
            } else {
              return field;
            }
          }
        ),
      }
    });
  };

const exportOptions = [
  {
    title: 'Export: CSV v1',
    action: () => {
      fetch(`/api/v1/studies/export/v1/${studyId}`)
      .then(res => res.text())
      .then(text => {
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", text]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `export_${studyId}_${new Date().toISOString().split('T')[0]}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // window.open(`data:text/csv;filename=test.x;charset=utf-8,` + encodeURIComponent("abc"), "", 'test.x')
      })
    },
  },
  {
    title: 'Export: CSV v2 - Calculated',
    action: () => {
      fetch(`/api/v1/studies/export/v2/${studyId}`)
      .then(res => res.text())
      .then(text => {
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", text]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `export_${studyId}_${new Date().toISOString().split('T')[0]}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // window.open(`data:text/csv;filename=test.x;charset=utf-8,` + encodeURIComponent("abc"), "", 'test.x')
      })
    },
  },
];

  return (
    <>
      {
        isLoadingStudy ? (
          <Spinner message={`Loading study details`}/>
        ) : (
          <Accordion
            expanded={expanded}
            style={{margin: '1em 0px'}}
          >
            <AccordionSummary
              onClick={() => setExpanded(!expanded)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                { expanded ? `Click to hide` : `Click to display details and actions` }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {/* <Grid item xs={8}>
                  <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} aria-label="basic tabs example">
                    <Tab label="Study details" />
                    <Tab label="Individual details" />
                    <Tab label="Questionnaire details" />
                  </Tabs>
                  <pre>
                    {
                      JSON.stringify(
                        0 === tab ? (
                          dataStudy.studyDetails
                        ) : 1 === tab ? (
                          dataStudy.individualDetails
                        ) : (
                          dataStudy.questionnaireDetails
                        )
                        ,
                        null,
                        2,
                      )
                    }
                  </pre>
                </Grid> */}
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      '& > *': {
                        m: 1,
                      },
                    }}
                  >
                    {/* <ButtonGroup
                      style={{width: '100%'}}
                      orientation="vertical"
                      aria-label="vertical outlined button group"
                    >
                      <Button onClick={handleEditStudyDetails}>Edit Study-details</Button>
                      <Button onClick={handleEditIndividualDetails}>Edit Individual-details</Button>
                      <Button onClick={handleEditQuestinnaireDetails}>Edit Questionnaire-details</Button>
                    </ButtonGroup> */}
                  </Box>
                  <SplitButton
                    options={exportOptions}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      }
      {
        isLoadingQuestionnaires ? (
          <Spinner message={`Loading study questionnaires`}/>
        ) : 0 === questionnairesData.length ? (
          <Alert severity="info">
            <AlertTitle>Currently, there are no questionnaires in this study</AlertTitle>
            Click <strong>NEW QUESTIONNAIRE</strong> to create one
          </Alert>
        ) : (
          <StatefulPaginatedTable
            columns={columns}
            sortedColumn={sortDir}
            sortByColumn={sortByColumn}
            rows={sortedData}
            rowsPerPage={ROWS_PER_PAGE_OPTIONS[0]}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsOnClick={({ id }) => navigate(`questionnaire/${id}`)}
          />
        )
      }
      <Button style={{width: '100%', marginTop: '1em'}} onClick={() => setIsNewQuestionnaire(true)}>
        New Questionnaire
      </Button>
      {isNewQuestionnare && (
        <NewQuestionnaireWizard
          cancelNewQuestionnaire={() => setIsNewQuestionnaire(false)}
          studyData={dataStudy}
        />
      )}
      {editDetails && (
        <EditDetailsDialog
          title={editDetails.title}
          data={editDetails.data}
          explanation={editDetails.explanation}
          cancel={() => setEditDetails(null)}
          save={console.log}
          handleValueChange={handleDetailValueChange}
          handleCheckboxChange={console.log}
        />
      )}
    </>
  );
}
