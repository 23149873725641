import { useLocation, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function AppBreadcrumbs() {
    const { pathname } = useLocation();

    if ('/' === pathname) {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">Home</Link>
        </Breadcrumbs>
      )
    }

    if ('/dev' === pathname) {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">Home</Link>
          <Typography color="text.primary">Developer</Typography>
        </Breadcrumbs>
      )
    }

    if (pathname.startsWith('/studies')) {
      if (2 === pathname.split('/').length) {
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">Home</Link>
            <Typography color="text.primary">Studies</Typography>
          </Breadcrumbs>
        );
      }
      if (3 === pathname.split('/').length) {
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">Home</Link>
            <Link underline="hover" color="inherit" href="/studies">Studies</Link>
            <Typography color="text.primary">{pathname.split('/')[2]}</Typography>
          </Breadcrumbs>
        );
      }
      if (5 === pathname.split('/').length) {
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">Home</Link>
            <Link underline="hover" color="inherit" href="/studies">Studies</Link>
            <Link underline="hover" color="inherit" href={`/studies/${pathname.split('/')[2]}`}>{pathname.split('/')[2]}</Link>
            <Typography color="text.primary">Questionnaire</Typography>
            <Typography color="text.primary">{pathname.split('/')[4]}</Typography>
          </Breadcrumbs>
        );
      }
    }

    if (pathname.toLowerCase() === '/users') {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">Home</Link>
          <Typography color="text.primary">Users</Typography>
        </Breadcrumbs>
      );
    }

    if (pathname.toLowerCase() === '/manual') {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">Home</Link>
          <Typography color="text.primary">Manual</Typography>
        </Breadcrumbs>
      );
    }

    return (
      <Typography variant='h2' color="text.primary">404 - PAGE MISSING</Typography>
    )
}