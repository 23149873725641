import { useState, Fragment } from "react";
import { FormRenderer } from "../../../../../components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getTemplatesByGroupIdAndStudyId, getInputTranslations, createTemplate, createQuestionnaire } from '../../../../../apis';
import TemplateSelector2 from "./TemplateSelector2";
import TemplateCreator from "./TemplateCreator";
import CustomTemplateCreator from "./CustomTemplateCreator";
import NewTemplateConfirmation from "./NewTemplateConfirmation";
import Grid from '@mui/material/Grid';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import TemplateSelectionConfirmation from "./TemplateSelectionConfirmation";
import useCredentials from "../../../../../hooks/useCredentials";

const GROUP_ID = 2;

const setLayout = (field) => ({layout: {}, data: {...field}});

const setDefaults = (field) => {
  switch (field.data.config.type) {
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      return {
        ...field,
        layout: {
          ...field.layout,
          value: field.data.default_value || '',
        },
      };
    case 'dropdown':
      return {
        ...field,
        layout: {
          ...field.layout,
          value:
            field.data.default_value
            || {
              title:
                field.data.is_required 
                ? ''
                : field.data.is_defaultable
                ? 'NO DEFAULT VALUE SELECTED'
                : 'DEFAULT VALUE CANNOT BE SELECTED'
              ,
              value: 0,
            },
          options:
            field.data.is_required
            ? [...field.data.config.options]
            : [{title:'NO SELECTION MADE', value: 0}, ...field.data.config.options]
        },
      };
    default:
      return field;
  }
}

const setDisabled = (field) => ({
  ...field, 
  layout: {
    ...field.layout, 
    disabled: !!field.data.default_value,
  },
});

const setValidity = (field) => {
  let validator;
  switch (field.data.config.type) {
    case 'checkbox':
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: '',
          validator: () => true,
        },
      };
    case 'text':
    case 'longtext':
    case 'number':
    case 'date':
      validator = (newValue) => !(field.data.is_required && String(newValue).trim() === '') ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value),
          validator,
        },
      };
    case 'dropdown':
      validator = (newValue) => !(field.data.is_required && newValue === 0) ? '' : 'No value set for mandatory field';
      return {
        ...field,
        layout: {
          ...field.layout,
          errMsg: validator(field.layout.value.value),
          validator,
        },
      };
    default:
      return field;
  }
}

const emtpyTemplateListInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>At the moment, this study has not quetionnaire templates</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>Click CREATE NEW TEMPLATE</li>
    </ul>
  </p>
</>);

const templateListInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>On the left you can select which template to use for the new questionnaire.</li>
      <li>You can also create a new template if the existing one do not match in input-list or language</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>Select a template</li>
    </ul>
  </p>
</>);

const templateCopyInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>On the left, you can select a language and a global-tamplate</li>
      <li>The displayed list of inputs can be used to create a new template for this study, by clicking COPY</li>
      <li>A completely new list of inputs can be assemble into a template, by clicking CREATE CUSTOM TEMPLATE</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>Create a new template by COPYing an existing one or createing a custom-template</li>
    </ul>
  </p>
</>);

const customTemplateInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>In the left column you can manually select the inputs for the new template</li>
      <li>In the left column you can use the "Use existing template as reference" dropdown to select inputs from an existing template</li>
      <li>In the middle column you can select the language for the new template</li>
      <li>In the middle colunn you can add custom inputs that do not appear in the left colunn</li>
    </ul>
  </p>
</>);

const newTemplateValidationInfo = (<>
  <p>
    <h4>Explanation:</h4>
    <ul>
      <li>On the left, you can examine the list of inputs that will compose the new template.</li>
    </ul>
  </p>
  <p>
    <h4>Requirements:</h4>
    <ul>
      <li>Give the new template a name</li>
    </ul>
  </p>
</>);

export default function NewQuestionnaireWizard({ cancelNewQuestionnaire, studyData }) {
  const { id: userId } = useCredentials();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading: isloadingTemplates, data: dataTemplates } = useQuery({
    queryKey: ['config', 'templates', GROUP_ID, studyData.study.id],
    queryFn: getTemplatesByGroupIdAndStudyId(GROUP_ID, studyData.study.id),
    onSuccess: () => console.log('2023-09-24T07:37:00.947Z'),
  });
  const { isLoading: isLoadingInputs, data: dataInputs } = useQuery({
    queryKey: ['config', 'inputs'],
    queryFn: getInputTranslations(),
    onSuccess: () => console.log('2023-09-24T07:37:33.195Z'),
  });
  const mutationSaveTemplate = useMutation({
    mutationFn: createTemplate,
    onSuccess: ({ templateId }) => {
      queryClient.setQueryData(
        ['config', 'templates', GROUP_ID, studyData.study.id],
        [
          ...queryClient.getQueryData(['config', 'templates', GROUP_ID, studyData.study.id]),
          {...newTemplate, id: templateId, language: selectedLanguage }
        ],
      );
      setNewTemplate({
        ...newTemplate,
        id: 0,
        inputs: [],
        freetext: [],
        title: '',
        errMsg: 'Must set name for the new template',
      });
      setActiveStep(0);
    },
  })
  const mutationCreateQuestionnaire = useMutation({
    mutationFn: createQuestionnaire,
    onSuccess: ({ questionnaireId }) => navigate(`/studies/${studyData.study.id}/questionnaire/${questionnaireId}`),
  })


  const [flowState, setFlowState] = useState([
    {
      title: 'Template selection',
    },
    {
      title: 'Template creation',
    },
    {
      title: 'Custom template creation',
    },
    {
      title: 'New template validation',
    },
    {
      title: 'Template selection verification',
    },
  ])
  
  // const [alertState, setAlertState] = useState({ message: '', severity: 'error', open: false })
  const [activeStep, setActiveStep] = useState(0);
  const [newTemplate, setNewTemplate] = useState({
    id: 0,
    studyId: Number(studyData.study.id),
    inputs: [],
    freetext: [],
    isNew: true,
    title: '',
    errMsg: 'Must set name for the new template',
    titleTouched: false,
    titleValidator: (v) => '' !== v.trim() ? '' : 'Must set name for the new template',
  });
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState({id: 0});

  const saveNewTemplate = () => mutationSaveTemplate.mutate({
    studyId: newTemplate.studyId,
    title: newTemplate.title,
    language: selectedLanguage,
    inputs: newTemplate.inputs,
    freetext: newTemplate.freetext,
  });

  if (isLoadingInputs || isloadingTemplates) return null;

  return (
    <Dialog open maxWidth='lg' fullWidth>
      <DialogTitle>
        <Stepper activeStep={activeStep}>
          {flowState.map(({ title }) => 
            <Step
              key={title}
              completed={
                (
                  [
                    'Template creation',
                    'Custom template creation',
                    'New template validation',
                  ].includes(title)) && 4 === activeStep
                  ? false
                  : 'Template selection' === title && activeStep > 0
                  ? true
                  : 'Template creation' === title && activeStep > 1
                  ? true
                  : 'Custom template creation' === title && activeStep > 2
                  ? true
                  : 'New template validation' === title && activeStep > 3
                  ? true
                  : false
              }
            >
              <StepLabel
                // error
                optional={[
                  'Template creation',
                  'Custom template creation',
                  'New template validation',
                ].includes(title) && (
                  <Typography variant="caption" /*color="error"*/>
                    Optional
                  </Typography>
                  )
                }
              >
                {title}
              </StepLabel>
            </Step>
           )}
        </Stepper>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={0}>
          <Grid item xs={2 === activeStep ? 8 : 6}>
            <Box sx={{ width: "100%" }}>
              <Fragment>
                <Typography component={"div"} sx={{ mt: 2, mb: 1, pr:2 }}>
                  {
                    0 === activeStep ? (<>
                      {dataTemplates.filter(({ studyId }) => studyId === Number(studyData.study.id)).length > 0 && (
                      <TemplateSelector2
                        style={{}}
                        header={'Select a template'}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        selectedLanguage={selectedLanguage}
                        translatedInputs={dataInputs[0]}
                        templates={dataTemplates.filter(({ studyId }) => studyId === Number(studyData.study.id))}
                      /> )} </>
                    ) : 1 === activeStep ? (
                      <TemplateCreator
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        languages={dataInputs[1]}
                        templates={dataTemplates.filter(({ studyId }) => !studyId)}
                        newTemplate={newTemplate}
                        setNewTemplate={({ title, inputs }) => {
                          const dateArr = new Date().toISOString().split('T')[0].split('-')
                          setNewTemplate({
                            ...newTemplate,
                            title: `[${title} - ${dataInputs[1].find(lang => lang.id === selectedLanguage).title}]${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`,
                            errMsg: '',
                            inputs: [...inputs],
                          })}
                        }
                        translatedInputs={dataInputs[0]}
                      />
                    ) : 2 === activeStep ? (
                      <CustomTemplateCreator
                        templates={dataTemplates}
                        languages={dataInputs[1]}
                        translatedInputs={dataInputs[0]}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        selectedTemplate={newTemplate}
                        setSelectedTemplate={setNewTemplate}
                      />
                    ) : 3 === activeStep ? (
                      <NewTemplateConfirmation
                        language={dataInputs[1].find(({ id }) => id === selectedLanguage)}
                        template={newTemplate}
                        errMsg={newTemplate.titleTouched ? newTemplate.errMsg : ''}
                        templateTitle={newTemplate.title}
                        setTemplateTitle={(title) => setNewTemplate({...newTemplate, title, titleTouched: true, errMsg: newTemplate.titleValidator(title)})}
                        translatedInputs={dataInputs[0]}
                      />
                    ) : (
                      <TemplateSelectionConfirmation
                      />
                    ) 
                  }
                </Typography>
              </Fragment>
            </Box>
          </Grid>
          <Grid item xs={2 === activeStep ? 4 : 6} style={{borderLeft: '1px black solid', height: '100%', paddingLeft: '1em'}}>
            <Box sx={{ width: "100%", height: '100%' }}>
              {
                0 === activeStep
                ? 0 === dataTemplates.filter(({studyId}) => studyId === studyData.study.id).length ? emtpyTemplateListInfo : templateListInfo
                : 1 === activeStep
                ? templateCopyInfo
                : 2 === activeStep
                ? customTemplateInfo
                : 3 === activeStep
                ? newTemplateValidationInfo
                : <></>
              }
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{mr:'auto'}}
          onClick={cancelNewQuestionnaire}
        >
          Cancel
        </Button>
        {
          0 === activeStep ?
          (
            <>
              <Button
                color='secondary'
                onClick={() => setActiveStep(1)}
              >
                Create new template
              </Button>
              <Button
                disabled={0 === selectedTemplate.id}
                onClick={() => setActiveStep(4)}
              >
                Use selected template
              </Button>
            </>
          ) : 1 === activeStep ? (
            <>
              <Button
                color='secondary'
                onClick={() => setActiveStep(2)}
              >
                Create custom template
              </Button>
              <Button
                disabled={!newTemplate.inputs || (newTemplate.inputs && 0 === newTemplate.inputs.length)}
                onClick={() => setActiveStep(3)}
              >
                Copy
              </Button>
            </>
          ) : 2 === activeStep ? (
            <Button
              onClick={() => setActiveStep(3)}
            >
              Continue
            </Button>
          ) : 3 === activeStep ? (
            <Button
                disabled={'' !== newTemplate.errMsg}
                onClick={saveNewTemplate}
              >
                Confirm template creation
            </Button>
          ) : (
            <Button
                onClick={() => {
                  mutationCreateQuestionnaire.mutate({
                    userId: userId,
                    studyId: Number(studyData.study.id),
                    language: selectedTemplate.language,
                    templateId: selectedTemplate.id,
                  });
                }}
              >
                DONE
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};