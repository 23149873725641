import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { UserContext  } from "./context/user";
import AppBar from './components/AppBar';
import Grid from '@mui/material/Grid';
import AppBreadcrumbs from "./components/Breadcrumbs";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Homepage,
  LoginPage,
  StudiesPage,
  ManualPage,
  ConfigPage,
  UsersPage,
  DevPage,
} from "./pages";
import { QuestionnaireDisplay, StudyDisplay } from "./pages/studies/components";


const routingMenu = [
    {
      path: "/",
      title: "Homepage"
    },
    // {
    //   path: "/login",
    //   title: "Login"
    // },
    {
      path: "/studies",
      title: "Studies"
    },
    {
      path: "/manual",
      title: "Manual"
    },
    // {
    //   path: "/config",
    //   title: "Config"
    // },
    // {
    //   path: "/users",
    //   title: "Users"
    // }
];

const queryClient = new QueryClient();

export default function AuthenticatedApp({ user }) {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AppBar routes={routingMenu} user={user} />
                <div
                    style={{
                        // backgroundColor:'lime',
                        position: 'fixed',
                        top:'5em',
                        bottom:'0px',
                        left: '0em',
                        right: '0em',
                        overflowY:'scroll',
                        paddingLeft:'1em',
                        paddingRight:'1em',
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={0} sm={1} md={2}></Grid>
                        <Grid item xs={12} sm={10} md={8}>
                            <AppBreadcrumbs/>
                            <Routes>
                                <Route path="/" element={<Homepage />} />
                                <Route path="login" element={<Homepage />} />
                                <Route path="studies" element={<StudiesPage />} />
                                <Route path="studies/:studyId" element={<StudyDisplay />} />
                                <Route
                                    path="studies/:studyId/questionnaire/:questionnaireId"
                                    element={<QuestionnaireDisplay />}
                                />
                                <Route path="manual" element={<ManualPage />} />
                                <Route path="config" element={<ConfigPage />} />
                                <Route path="users" element={<UsersPage />} />
                                <Route path="dev" element={<DevPage />} />
                            </Routes>
                        </Grid>
                        <Grid item xs={0} sm={1} md={2}></Grid>
                    </Grid>
                </div>
            </BrowserRouter>
        </QueryClientProvider>
    );
};