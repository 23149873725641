import AppBar from "./components/AppBar";
import Grid from '@mui/material/Grid';
import { BrowserRouter, Routes, Route } from "react-router-dom";import {
    Homepage,
    LoginPage,
    DevPage,
  } from "./pages";

export default function UnauthenticatedApp({
    login,
    signup,
}) {
    return (
        <BrowserRouter>
            <AppBar routes={[{ path: "/", title: "Home" }, { path: "/login", title: "Login" }]} />
            <div
                style={{
                    // backgroundColor:'lime',
                    position: 'fixed',
                    top:'5em',
                    bottom:'0px',
                    left: '0em',
                    right: '0em',
                    overflowY:'scroll',
                    paddingLeft:'1em',
                    paddingRight:'1em',
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={0} sm={1} md={2}></Grid>
                        <Grid item xs={12} sm={10} md={8}>
                            <Routes>
                                <Route path="/" element={<Homepage />} />
                                <Route path="login" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="studies" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="studies/:studyId/questionnaire/:questionnaireId" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="manual" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="config" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="users" login={() => console.log('asdf')} element={<LoginPage login={login} signup={signup} />} />
                                <Route path="dev" login={() => console.log('asdf')} element={<DevPage />} />
                            </Routes>
                        </Grid>
                    <Grid item xs={0} sm={1} md={2}></Grid>
                </Grid>
            </div>
        </BrowserRouter>
    );
}