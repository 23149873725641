function getSuggestion(language, referent, meaningVal) {
    return async function() {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/v1/references/suggestion`,
            {
                method: 'POST',
                body: JSON.stringify({
                    language,
                    referent,
                    meaningVal,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
};

export {
    getSuggestion,
}