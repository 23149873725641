import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditInput from "./EditInput";
import Codings from "./Codings";
import CodingsDisplayTable from "./CodingsDisplayTable";
import useDirection from "../../../../hooks/useDirection";


export default function QuestionnaireInputWithAnswer({
  expanded,
  onExpand,
  input: { 
    answer_id,
    answer_text,
    codings,
    freetext,
    input_id,
    input_order,
    translation,
    upsert,
    editCodings,
  },
}) {
  const [isDisplayImage, setIsDisplayImage] = useState(false);
  const [isEditInput, setIsEditInput] = useState(null);
  // const [image, setImage] = useState(null);
  const dir = useDirection();

  const answerMissing = !answer_id; //0 === text.length && 0 === image_id.length;
  const codingsMissing = 0 === codings.length;

  const saveAnswer = (answerText) => {
    upsert(answerText);
    setIsEditInput(false);
  }

  return (
    <>
      {/* {(answer_id == 12345) && (<Codings input={{
        name: translation,
        answer: {codings: []},
        answer_id,
        answer_text,
        codings,
        freetext,
        input_id,
        input_order,
        translation,
        upsert,
      }}/>)} */}
      {/* <div>{JSON.stringify(answer_id)}</div> */}
      <div>
        {/*image_id.length*/ false && (
          <Button onClick={() => setIsDisplayImage(true)}>image</Button>
        )}
      </div>
      {/* {isDisplayImage && (
        <Dialog open={true}>
          <DialogContent>
            <img src={image.bindata} alt={image.name}></img>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDisplayImage(false)}>close</Button>
          </DialogActions>
        </Dialog>
      )} */}
      {/*isDisplayCodings && (
        <Codings
          input={input}
          close={() => setIsDisplayCodings(false)}
          save={handleSave}
        ></Codings>
      )*/}
      {isEditInput && (
        <EditInput
          inputValue={translation}
          answerText={answer_text}
          cancel={() => setIsEditInput(false)}
          save={saveAnswer}
        />
      )}
      <Accordion expanded={expanded} onChange={onExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          dir={dir}
        >
          <Typography sx={{pr: '1em', whiteSpace: 'nowrap', backgroundColor: freetext ? 'rgb(221,255,221)' : 'inherit'}} variant="h6" gutterBottom>
            {translation}:
          </Typography>
          <Typography sx={{width:'100%', color: 'lightslategray', direction: dir}} variant="h6">
            {answer_text}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid sx={{mb:'1em'}} container spacing={1}>
            <Grid item sm={0} md={1}></Grid>
            <Grid item sm={12} md={4}>
              <Button
                sx={{width:'100%'}}
                startIcon={<ArrowUpwardIcon />}
                endIcon={<ArrowUpwardIcon />}
                onClick={() => setIsEditInput(true)}
              >
                Edit Answer
              </Button>
            </Grid>
            <Grid item sm={0} md={2}></Grid>
            <Grid item sm={12} md={4}>
              <Button
                sx={{width:'100%'}}
                startIcon={<ArrowDownwardIcon />}
                endIcon={<ArrowDownwardIcon />}
                disabled={answerMissing && codingsMissing}
                onClick={editCodings}
              >
                Edit Meaning Units
              </Button>
            </Grid>
            <Grid item sm={0} md={1}></Grid>
          </Grid>
          { answerMissing && codingsMissing
            ? (
              <Alert severity="info">
                <AlertTitle>Currently, no answer is set for this input</AlertTitle>
                You can provide an answer by clicking <strong>EDIT ANSWER</strong>
              </Alert>
              )
            : !codingsMissing
            ? (<CodingsDisplayTable codings={codings}/>)
            : (
                <Alert severity="info">
                  <AlertTitle>Currently, no meaning-units exist for this answer</AlertTitle>
                  You can add meaning-units by clicking <strong>EDIT MEANING UNITS</strong>
                </Alert>
              )
          }
        </AccordionDetails>
      </Accordion>
    </>
  );
}