
import FormControl from "@mui/material/FormControl";
import { InputLabel, Select, MenuItem } from "@mui/material";

export default function LanguageSelector({
    selectedLanguage,
    setSelectedLanguage,
    languages,
    header,
}) {
    return (
      <FormControl fullWidth>
        <InputLabel>{header}</InputLabel>
        <Select
          label={header}
          onChange={({ target: { value } }) => setSelectedLanguage(value)}
          value={selectedLanguage}
        >
          {
            languages.map(({ id, title }) => (
              <MenuItem key={`language_${id}`} value={id}>{title}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
};