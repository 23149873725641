function getResourceConfig(resource, groupId) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/config/group/${groupId}/${resource}`);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
}

function getTemplatesByGroupIdAndStudyId(groupId, studyId) {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/config/group/${groupId}/study/${studyId}/templates`);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
}

function getInputTranslations() {
    return async function() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/v1/config/inputs`);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
}

export {
    getResourceConfig,
    getTemplatesByGroupIdAndStudyId,
    getInputTranslations,
}