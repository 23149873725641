import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const handleFirstPageButtonClick = () => onPageChange(0);
  const handleBackButtonClick = () => onPageChange(page - 1);
  const handleNextButtonClick = () => onPageChange(page + 1);
  const handleLastPageButtonClick = () => onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
      <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
      <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
      <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
      <LastPageIcon />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function StatefulPaginatedTable({
  columns,
  sortByColumn,
  sortedColumn: {columnName, direction},
  rows,
  rowsPerPage,
  rowsPerPageOptions,
  rowsOnClick,
}) {
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage);
  const [page, setPage] = useState(0);
  const emptyRowsTotal = page > 0 ? Math.max(0, (1 + page) * localRowsPerPage - rows.length) : 0

  useEffect(() => setPage(0), [localRowsPerPage]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead style={{backgroundColor: 'lightgray'}}>
          <TableRow>
            {
              columns.map(column => (
                <TableCell align="left" key={column.name}>
                  <TableSortLabel
                    active={columnName === column.field}
                    direction={columnName === column.field ? direction : 'asc'}
                    onClick={sortByColumn(column.field)}
                  >
                    {column.name}
                  </TableSortLabel>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.slice(page * localRowsPerPage, (page+1) * localRowsPerPage).map((row) => (
              <TableRow hover key={`${row.id}`} onClick={() => rowsOnClick(row)}>
                {
                  columns.map(({field, mutator}) => (
                    <TableCell key={`${row.id}_${field}`} component="th" scope="row">
                      {mutator(row[field])}
                    </TableCell>
                  ))
                }
              </TableRow>
              )
            )
          }
          {emptyRowsTotal > 0 && (
            <TableRow style={{ height: 53 * emptyRowsTotal }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={columns.length}
              count={rows.length}
              rowsPerPage={localRowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={setPage}
              onRowsPerPageChange={({ target: { value }}) => setLocalRowsPerPage(value)}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
